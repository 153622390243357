import { RolesType, StatusType } from 'components/FilterModal/FilterHelper';
import Loading from 'components/UI/Loading';
import { useDebounce } from 'hooks/useDebounce';
import PendingOnboardingNotice from 'pages/Budgets/PendingOnboardingNotice';
import PeopleEmptyStateData from 'pages/People/peopleEmptystateData';
import { columnsBeneficiaries } from 'utils/mockData';
import { buildBeneficiariesTableData } from 'utils/helper';
import Table from 'components/Table';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { filterBeneficiaries, getBeneficiaries } from 'redux/actions/BeneficiariesAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import NoData from '../../components/NoData';
import { Skeleton } from 'antd';
import TopBar from '../../components/TopBar';
import BeneficiariesModal from './BeneficiariesModal';
import BeneficiariesTable from './BeneficiariesTable/BeneficiariesTable';

import './style.less';

const Beneficiaries = ({ isOpen, setIsOpen }) => {
  const location = useLocation();
  const history = useHistory();
  // const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState([...StatusType, ...RolesType]);
  const [isFillData, setIsFillData] = useState(false);
  const [filteredQuery, setFilteredQuery] = useState({});
  const [filterProgramatically, setFilterProgramatically] = useState(false);
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 600);

  const {
    getBeneficiaries: { data = {}, loading },
    isFilterBeneficiaries,
  } = useSelector(({ beneficiaries }) => beneficiaries);
  const { beneficiaries = [], meta = {} } = data;

  const {
    getBudget: { data: budgetData = {} },
  } = useSelector(({ budgets }) => budgets);

  const { permissions } = allPermissions();
  const canViewEmployee = hasPermission({
    permissions,
    scopes: ['employee-*', 'employee-view'],
  });
  const canCreateEmployee = hasPermission({
    permissions,
    scopes: ['employee-*', 'employee-create'],
  });
  useEffect(() => {
    if (location.state?.isModal) {
      toggleHandler();
      history.replace({ state: {} });
    }
  }, [location]);

  useEffect(() => {
    return () => {
      if (filtered && canViewEmployee && isFilterBeneficiaries)
        dispatch(getBeneficiaries({ excludeInactiveBudgets: true }));
    };
  }, [filtered]);

  useEffect(() => {
    if (!!budgetData?.budgets?.length) {
      if (!isFillData) {
        setIsFillData(true);
        const data = [];
        budgetData?.budgets.forEach((b) => {
          const { code, name } = b;
          data.push({
            value: code,
            label: name,
            isSelected: false,
          });
        });
        setFilterData([{ title: 'Budget', list: data }, ...filterData]);
      }
    }
  }, [budgetData?.budgets]);

  useEffect(() => {
    if (isFilterBeneficiaries) {
      setFilterProgramatically(true);
      setFilterData((prevValue) => {
        let data = [...prevValue];
        data = filterData.filter((option) => option?.title?.toLowerCase() !== 'status');
        return [
          {
            title: 'Status',
            list: [
              {
                value: 'invited',
                label: 'Invited',
                isSelected: true,
              },
              {
                value: 'active',
                label: 'Active',
                isSelected: false,
              },
              {
                value: 'inactive',
                label: 'Disabled',
                isSelected: false,
              },
            ],
          },
          ...data,
        ];
      });
      handleFilter({ status: ['invited'] });
    }
    return () => {
      if (isFilterBeneficiaries) {
        dispatch(filterBeneficiaries(false));
      }
    };
  }, [isFilterBeneficiaries]);

  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  const handleFilter = (query) => {
    dispatch(getBeneficiaries({ ...query, excludeInactiveBudgets: true }));
    setFilteredQuery(query);
    isFiltered.current = !!Object.keys(query).length;
  };

  // Handle search change after debounce
  useEffect(() => {
    if (debouncedValue && canViewEmployee) {
      filteredQuery.search = debouncedValue;
      dispatch(
        getBeneficiaries({
          ...filteredQuery,
          search: debouncedValue,
          excludeInactiveBudgets: true,
        }),
      );
    }
    if (!debouncedValue && filtered && canViewEmployee) {
      delete filteredQuery.search;
      dispatch(getBeneficiaries({ ...filteredQuery, excludeInactiveBudgets: true }));
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const show = !!beneficiaries?.length || (filtered && !beneficiaries?.length);

  const Loader = () => (
    <section className="pt-3 fade-in">
      <div className="d-flex">
        <Skeleton.Button
          active
          shape="square"
          style={{ borderRadius: 8, height: '40px', width: '16rem' }}
        />

        <div className="ms-auto d-flex gap-2">
          <Skeleton.Button
            active
            shape="square"
            style={{ borderRadius: 8, height: '40px', width: '7rem' }}
          />
        </div>
      </div>

      <div className="mt-4">
        <Table
          columns={columnsBeneficiaries}
          data={buildBeneficiariesTableData(beneficiaries || [])}
          hasCheckBox={false}
          loading
        />
      </div>
    </section>
  );

  if (loading && !filtered) return <Loader />;

  return (
    <div className="beneficiaries-wrapper">
      <PendingOnboardingNotice />

      <TopBar
        showFilter={show}
        setSearchVal={setSearch}
        searchVal={search}
        withOutSearch
        showBarSearch={show}
        filterData={filterData}
        inputPlaceholder="Search by name or email"
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        filterProgramatically={filterProgramatically}
        handleFilterApply={handleFilter}
        withDate
      />
      {!!beneficiaries.length ? (
        <BeneficiariesTable
          beneficiaries={beneficiaries}
          meta={meta}
          filteredQuery={filteredQuery}
        />
      ) : (
        <div>
          {filtered ? (
            <div className="tabinnerWrapper">
              <NoData
                headerText="You have no beneficiaries for this filter"
                bodyText="Alter filter to see beneficiaries..."
                withButton={false}
              />
            </div>
          ) : (
            <PeopleEmptyStateData
              setIsBeneficiaryOpen={setIsOpen}
              showAction={canCreateEmployee}
            />
          )}
        </div>
      )}

      <BeneficiariesModal isOpen={isOpen} toggleHandler={toggleHandler} />
    </div>
  );
};

export default Beneficiaries;
