import { ArrowUpIcon, PlusIcon } from 'assets/icons';
import ImgCard from 'components/UI/ImgCard';
import BadgeType from 'components/UI/Table/BadgeType';
import { capitalizeFirstLetter, getInitials } from '../../../utils/helper';
import CustomButton from '../Components/CustomButton';
import { Skeleton } from 'antd';
import AddIcon from 'assets/icons/AddIcon';

const ProfileInformation = ({
  basicInformation = [],
  bankInformation = [],
  userInformation,
  onSendFunds,
  onEdit,
  onDelete,
  deleteText,
  sendText,
  loading,
  isCustomer,
}) => {
  const Loader = () => {
    return (
      <div>
        <div>
          <div className="profile-header" style={{ marginBottom: '10px' }}>
            <Skeleton.Avatar active size={96} style={{ marginBottom: '8px' }} />
            <Skeleton active title={true} width={'10'} paragraph={false} />
          </div>
          <div className="d-flex justify-content-between mb-4">
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton.Button active key={index} />
            ))}
          </div>
          <div>
            {Array.from({ length: 3 }).map((_, index) => {
              return (
                <div key={index} className="d-flex justify-content-between mb-2">
                  <Skeleton.Input active size={20} />
                  <Skeleton.Input active size={20} />
                </div>
              );
            })}
          </div>
        </div>
        <div className="divider"></div>
        <div className="more-profile-information-container pb-5">
          {Array.from({ length: 6 }).map((_, index) => {
            return (
              <div key={index}>
                <Skeleton
                  active
                  title={true}
                  paragraph={{
                    rows: 1,
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div>
            <div className="profile-header">
              {userInformation.avatar ? (
                <img
                  className="rounded-circle"
                  height={96}
                  width={96}
                  src={userInformation.avatar}
                />
              ) : (
                <div className="profile-card__img">
                  <ImgCard
                    size="x2large"
                    gap={false}
                    initials={getInitials(null, null, userInformation.name)}
                    fullWidth={false}
                  />
                </div>
              )}
              <h4 className="mt-3">{userInformation['name']}</h4>
              <p className="profile-grey-text-color">{userInformation['subText']}</p>
            </div>
            <div className="d-flex justify-content-between mb-4">
              <CustomButton
                className="me-3 flex-grow-1 dark-outline-button"
                onClick={() => {
                  onSendFunds();
                }}
                // fullWidth={true}
                disabled={!userInformation?.['canSendFunds']}
              >
                {!isCustomer && (
                  <span
                    className="position-relative"
                    style={{ marginRight: 4, bottom: 1 }}
                  >
                    <ArrowUpIcon
                      stroke="#44403c"
                      height="18"
                      width="18"
                      className="mr-2"
                    />
                  </span>
                )}
                {sendText}
              </CustomButton>
              {!isCustomer && onEdit && (
                <CustomButton
                  className="me-3 flex-grow-1 dark-outline-button"
                  onClick={() => {
                    onEdit();
                  }}
                  // fullWidth={true}
                  disabled={!userInformation?.['canEdit']}
                >
                  Edit
                </CustomButton>
              )}
              <CustomButton
                className="flex-grow-1 danger-outline-button"
                onClick={() => {
                  onDelete();
                }}
                // fullWidth={true}
                disabled={!userInformation?.['canDelete']}
              >
                {deleteText}
              </CustomButton>
            </div>
            <div>
              {bankInformation?.map((option, index) => {
                return (
                  <p key={index} className="d-flex justify-content-between">
                    <span className="text-xs profile-grey-text-color">
                      {option.label}
                    </span>
                    {option.value ? (
                      <span className="text-sm">{option.value}</span>
                    ) : (
                      <span className="text-sm cursor" onClick={option?.action?.onClick}>
                        {option?.action?.label}
                      </span>
                    )}
                  </p>
                );
              })}
            </div>
          </div>
          <div className="divider"></div>
          <div className="more-profile-information-container pb-5">
            {basicInformation?.map((option, index) => {
              return (
                <div key={index}>
                  <span className="text-xs">{option.label}</span>
                  {option.type === 'text' && (
                    <span className="text-sm">{option.value}</span>
                  )}
                  {option.type === 'badge' && (
                    <BadgeType
                      value={{
                        value: capitalizeFirstLetter(option.value),
                        color: option.color,
                      }}
                    />
                  )}

                  {option.type === 'badge list' && (
                    <>
                      {!!option.value.length ? (
                        <>
                          <div className="budgets-flex-container">
                            {option.value.map((item, itemIndex) => {
                              return (
                                <BadgeType
                                  key={itemIndex}
                                  value={{
                                    value: capitalizeFirstLetter(item.name),
                                    color: item.color,
                                  }}
                                />
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        <span className="text-sm">—</span>
                      )}
                    </>
                  )}

                  {option.type === 'has avatar' && (
                    <div className="d-flex align-items-center">
                      <ImgCard
                        gap={false}
                        initials={getInitials(null, null, option.value)}
                        fullWidth={false}
                      />
                      <span className="ms-2 text-sm">{option.value}</span>
                    </div>
                  )}

                  {option.type === 'action' && (
                    <span className="text-sm cursor" onClick={option?.action?.onClick}>
                      {option?.action?.label}
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
export default ProfileInformation;
