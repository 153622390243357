import { ArrowLeftOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap';

import CustomTextarea from 'components/UI/CustomTextarea';
import { toastError } from 'components/UI/toast';
import useTextCounter from 'hooks/useTextCounter';
import { useDispatch, useSelector } from 'react-redux';
import { createCategories } from 'redux/actions/CategoryAction';
import './styles.scss';

const CreateCategory = () => {
  const initial = {
    description: undefined,
    name: undefined,
    limit: undefined,
    bufferAmount: undefined,
    bufferPercentage: undefined,
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    createCategories: {
      success: createSuccess,
      loading: createLoading,
      data: { category = {} } = {},
    },
  } = useSelector(({ categories }) => categories);

  const [categoryData, setCategoryData] = useState({ ...initial });
  const [bufferAmount, setBufferAmount] = useState(0);

  useEffect(() => {
    if (categoryData.limit && categoryData.bufferPercentage) {
      if (categoryData.limit === '0' || categoryData.bufferPercentage === '0')
        return setBufferAmount(0);
      setBufferAmount(
        Number(categoryData.limit) / Number(categoryData.bufferPercentage).toFixed(2),
      );
    } else {
      setBufferAmount(0);
    }
  }, [categoryData.limit, categoryData.bufferPercentage]);

  useEffect(() => {
    if (createSuccess) {
      history.push(`/compliances/categories/${category?.code}/details`);
    }
  }, [createSuccess]);

  const onHandleCancel = () => {
    history.goBack(-1);
  };

  const handleDescriptionChange = (value) => {
    setCategoryData({
      ...categoryData,
      description: value,
    });
  };

  const { text, charCount, handleCharChange } = useTextCounter(
    categoryData?.description,
    250,
    handleDescriptionChange,
  );

  const handleSubmit = () => {
    const { name, description, limit } = categoryData;

    if (!name) return toastError('Please input name');

    const payload = {
      name,
      description: description ?? undefined,
      limit: limit ? limit * 100 : undefined,
    };

    dispatch(createCategories(payload));
  };

  return (
    <div className="pt-5 pb-3 mx-auto policy-page-body">
      <div className="approval-header">
        <div className="back-click mb-4" onClick={onHandleCancel}>
          <ArrowLeftOutlined />
          Back
        </div>
      </div>

      <div className="information-wrapper create-form form-wrapper__2 w-100 position-relative">
        <form>
          <div className="d-flex flex-sm-wrap align-items-start justify-content-between">
            <div>
              {' '}
              <h6 className="text-lg">Create a category</h6>
              <p className="text-sm">Organise and access your categories</p>
            </div>
            <div className="d-flex">
              <CustomButton
                onClick={onHandleCancel}
                className="add-button add-action me-3"
                customClass={true}
              >
                Cancel
              </CustomButton>
              <CustomButton
                className="add-button dark-button"
                customClass={true}
                onClick={handleSubmit}
                loading={createLoading}
              >
                Create
              </CustomButton>
            </div>
          </div>
          <div className="divider"></div>
          <Row>
            <CustomInput
              label="Category name"
              placeholder="Enter name"
              text="text"
              name="approvalName"
              className="mb-3"
              id="name"
              onChange={(event) =>
                setCategoryData({ ...categoryData, name: event.target.value })
              }
              value={categoryData?.name}
              md={8}
            />
            <Col className="mb-3" md={8}>
              <CustomTextarea
                label="Description(Optional)"
                name="description"
                placeholder="Enter description"
                value={text || categoryData?.description}
                labelClass="text-sm"
                charCount={charCount}
                onChange={handleCharChange}
                rowSize={4}
              />
            </Col>
          </Row>

          <Row className="mb-2 buffer">
            <Col md={8}>
              <CustomInput
                placeholder="0.00"
                type="number"
                label="Category limit (optional)"
                isAmount
                otherCurrency={false}
                name="limit"
                onChange={(event) =>
                  setCategoryData({ ...categoryData, limit: event.target.rawValue })
                }
                value={categoryData.limit}
              />
            </Col>
          </Row>
        </form>

        <div style={{ bottom: 0 }} className="position-absolute w-100">
          <div className="divider"></div>

          <div className="d-flex flex-wrap justify-content-end mt-3">
            <CustomButton
              onClick={onHandleCancel}
              className="add-button add-action me-3"
              customClass={true}
            >
              Cancel
            </CustomButton>
            <CustomButton
              className="add-button dark-button"
              customClass={true}
              onClick={handleSubmit}
              loading={createLoading}
            >
              Create
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCategory;
