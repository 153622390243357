import classNames from 'classnames';
import TotalAmount from 'pages/Dashboard/Overview/components/TotalAmount';
import CurrencyFormat from 'react-currency-format';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'components/UIHooks/Rechart';
// import { data as noChartDatas, noData } from '../..//mock';
import { ReactComponent as ArrowUp } from 'assets/icons/arrowUp-green.svg';
import { getCurrency } from 'utils/helper';
import { numFormatter } from 'utils/utility';

const AxisStyle = {
  fontSize: 12,
  fill: '#57534E',
};
const gridStyle = {
  stroke: '#ccc',
  strokeWidth: 0.5,
};

const noChartData = [
  { name: 'Jan', spent: 0 },
  { name: 'Feb', spent: 0 },
  { name: 'Mar', spent: 0 },
  { name: 'Apr', spent: 0 },
  { name: 'May', spent: 0 },
  { name: 'Jun', spent: 0 },
  { name: 'Jul', spent: 0 },
  { name: 'Aug', spent: 0 },
  { name: 'Sep', spent: 0 },
  { name: 'Oct', spent: 0 },
  { name: 'Nov', spent: 0 },
  { name: 'Dec', spent: 0 },
];

const AreaChartView = ({
  size = 'big',
  data,
  totalPercentage,
  totalSpent,
  amountType,
  showTotalSpent = true,
  currency = 'NGN',
  noDataPlaceholder,
  color = '#d28b28',
  identifier = 'colorUv',
  hasCustomHeight,
}) => {
  const chartData = data.length ? data : noChartData;

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ fontSize: AxisStyle.fontSize }}>
          <p className="label">Date: {label}</p>
          <p className="label-data">
            <span>Spent:</span>
            {
              <CurrencyFormat
                prefix={getCurrency(currency)}
                value={numFormatter(payload[0].value)}
                displayType="text"
                thousandSeparator={true}
                isNumericString
              />
            }
          </p>
        </div>
      );
    }

    return null;
  };

  const formatYAxis = (value) =>
    `${getCurrency(currency)}${numFormatter(value, true, true)}`;
  const minInterval = Math.max(1, Math.ceil(data.length / 30));

  const uniqueMonths = new Set();

  return (
    <div
      className={classNames(
        'position-relative w-100 d-flex flex-column justify-content-center',
        `${!hasCustomHeight ? 'h-100' : ''}`,
      )}
    >
      {showTotalSpent && (
        <div className="chart-header">
          <TotalAmount
            type={totalSpent > 0 && amountType}
            title={
              (totalSpent > 0 || totalPercentage) && (
                <div className="d-flex align-items-center">
                  <CurrencyFormat
                    prefix={getCurrency(currency)}
                    value={numFormatter((totalSpent || 0) / 100)}
                    displayType="text"
                    thousandSeparator={true}
                    isNumericString
                  />
                  <span className="arrow-wrapper-percentage active ms-2">
                    <ArrowUp className="mr-3" /> {totalPercentage}%
                  </span>
                </div>
              )
            }
            subTitle={noDataPlaceholder}
          />
        </div>
      )}

      {!data.length && (
        <div className="position-absolute z-9 d-flex h-100 w-100 align-items-center justify-content-center text-center">
          <span className="bg-white p-2">No data to show</span>
        </div>
      )}

      <ResponsiveContainer width="100%" height={size === 'small' ? 100 : 220}>
        <AreaChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 10,
            right: 30,
            left: 5,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id={identifier} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={color} stopOpacity={0.9} />
              <stop offset="45%" stopColor={color} stopOpacity={0.5} />
              <stop offset="65%" stopColor={color} stopOpacity={0.3} />
              <stop offset="95%" stopColor={color} stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <CartesianGrid
            strokeDasharray="2 0"
            stroke={gridStyle.stroke}
            strokeWidth={gridStyle.strokeWidth}
            vertical={false}
          />
          <XAxis
            dataKey="name"
            tickMargin={12}
            padding={{ left: 12, right: 12 }}
            tick={AxisStyle}
            axisLine={false}
            tickLine={false}
            tickFormatter={(value) => {
              const [month, day] = value.split(' ');

              if (chartData.length < 9) {
                return `${month} ${parseInt(day, 10)}`;
              }

              if (uniqueMonths.has(month)) {
                return '';
              }
              uniqueMonths.add(month);
              return month;
            }}
            interval={minInterval}
          />
          <YAxis
            type="number"
            dataKey="spent"
            domain={[0, 'dataMax']}
            tickFormatter={formatYAxis}
            tick={AxisStyle}
            axisLine={false}
            tickLine={false}
          />
          {/* <YAxis tick={yAxisStyle} axisLine={false} tickLine={false} /> */}
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="monotone"
            dataKey="spent"
            stroke={color}
            strokeWidth={1.5}
            fillOpacity={1}
            fill={`url(#${identifier})`}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AreaChartView;
