import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ProfileInformation from './ProfileInformation';
import ProfileChart from './ProfileChart';
import ProfileTabs from './ProfileTabs';
import DateRangeFilter from 'components/DateRangeFilter';

import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup } from 'react-bootstrap';
import { ArrowLeftOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { formatNumThousandSeparator, getFromAndTo } from 'utils/utility';
import { RESET_FLAGS_INVOICE } from 'redux/reducers/InvoiceReducer';

import { subYears, subMonths } from 'date-fns';
import { selectDateRnage } from 'redux/actions/OverviewAction';
import ImgCard from 'components/UI/ImgCard';
import { getInitials } from 'utils/helper';
import BujetiPayModal from 'components/bujetiPayModal';
import { Skeleton, Tooltip } from 'antd';
import CustomButton from './CustomButton';
import { ArrowUpIcon } from 'assets/icons';

const ProfileComponent = ({
  children,
  basicInformation,
  bankInformation,
  informationBasedOnUserType,
  chartData,
  handleDateRangeSelection,
  userInformation,
  onEdit,
  onDelete,
  deleteText = 'Delete',
  sendText = 'Send funds',
  loading,
  chartLoading,
  isCustomer = false,
  billCustomer,
  hideChart = false,
  dateShortCut = true,
  onSend,
  analytics = null,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [selectedRange, setSelectedRange] = useState('Last 12 months');
  const [showSendFunds, setShowSendFunds] = useState(false);

  // useEffect(() => {
  //   setSelectedRange(selectedDateRange?.val);
  // }, [selectedDateRange.range]);

  const handleSelectRange = (title, date) => {
    const { from, to } = getFromAndTo(date);
    setSelectedRange(title);
    handleDateRangeSelection(from, to);
  };

  const handleSendFunds = () => {
    if (onSend) return onSend();
    if (isCustomer) {
      billCustomer();
    } else {
      setShowSendFunds(true);
    }
  };

  const handleDateOptionClick = (val) => {
    Date.prototype.addDays = function (days) {
      // Add days to given date
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    const today = new Date();

    setSelectedRange(val);

    switch (val) {
      case 'Last 7 days':
        dispatch(
          selectDateRnage({
            val,
            range: [{ startDate: today.addDays(-7), endDate: today }],
          }),
        );
        return handleSelectRange(val, [{ startDate: today.addDays(-7), endDate: today }]);
      case 'Last 30 days':
        dispatch(
          selectDateRnage({
            val,
            range: [{ startDate: today.addDays(-30), endDate: today }],
          }),
        );
        return handleSelectRange(val, [
          { startDate: today.addDays(-30), endDate: today },
        ]);
      case 'Last 12 months':
        const lastYearStartDate = subYears(today, 1);
        const lastYearEndDate = today;
        dispatch(
          selectDateRnage({
            val,
            range: [{ startDate: lastYearStartDate, endDate: lastYearEndDate }],
          }),
        );

        return handleSelectRange(val, [
          { startDate: lastYearStartDate, endDate: lastYearEndDate },
        ]);

      default:
        dispatch(
          selectDateRnage({
            val,
            range: [{ startDate: lastYearStartDate, endDate: lastYearEndDate }],
          }),
        );

        return handleSelectRange(val, [
          { startDate: lastYearStartDate, endDate: lastYearEndDate },
        ]);
    }
  };

  const handleBackRoute = () => {
    if (isCustomer) {
      history.push('/receivables/customers');
      dispatch({ type: RESET_FLAGS_INVOICE, blockType: 'fetchInvoices' });
    } else {
      history.goBack(-1);
    }
  };

  return (
    <Container className="mt-5 profile-page-wrapper px-0">
      <div className="back-click mb-4" onClick={() => handleBackRoute()}>
        <ArrowLeftOutlined />
        Back
      </div>
      <div className="d-flex mb-3">
        {loading ? (
          <Skeleton.Avatar active size={64} />
        ) : (
          <>
            {userInformation.avatar ? (
              <img
                className="rounded-circle"
                height={64}
                width={64}
                src={userInformation.avatar}
              />
            ) : (
              <ImgCard
                size="x2large"
                gap={false}
                initials={getInitials(null, null, userInformation.name)}
                fullWidth={false}
              />
            )}
          </>
        )}
        <div className="ms-3">
          {loading ? (
            <Skeleton
              active
              paragraph={{
                rows: 1,
              }}
              style={{ width: '300px' }}
            />
          ) : (
            <>
              <h3>{userInformation['name']}</h3>
              <p className="profile-grey-text-color">{userInformation['type']}</p>
            </>
          )}
        </div>
      </div>
      <div className="d-flex flex-wrap justify-content-between mb-3">
        {loading ? (
          <>
            <Skeleton.Button active size={35} style={{ width: '180px' }} />
            <Skeleton.Button active size={35} style={{ width: '100px' }} />
          </>
        ) : (
          <>
            {dateShortCut && (
              <ButtonGroup
                className="date-filter-button-group onDesktop"
                aria-label="Basic example"
              >
                <Button
                  onClick={() => {
                    handleDateOptionClick('Last 12 months');
                  }}
                  className={`${selectedRange === 'Last 12 months' ? 'active' : ''}`}
                >
                  12 months
                </Button>
                <Button
                  onClick={() => {
                    handleDateOptionClick('Last 30 days');
                  }}
                  className={`${selectedRange === 'Last 30 days' ? 'active' : ''}`}
                >
                  30 days
                </Button>
                <Button
                  onClick={() => {
                    handleDateOptionClick('Last 7 days');
                  }}
                  className={`${selectedRange === 'Last 7 days' ? 'active' : ''}`}
                >
                  7 days
                </Button>
              </ButtonGroup>
            )}
            <DateRangeFilter
              handleSelectRange={handleSelectRange}
              selectedRange={selectedRange}
              numberOfMonths={2}
              minDate={subMonths(new Date(), 11)} // Set the minimum date to 12 months ago
              maxDate={new Date()}
            />
          </>
        )}
      </div>

      <section className="overview-holder" style={{ height: `calc(100vh - 15.5rem)` }}>
        <Row className="border rounded w-100 m-0 p-0">
          <Col xl={8} className="py-4 border-end">
            {!hideChart &&
              (chartLoading ? (
                <>
                  <Skeleton
                    active
                    paragraph={{
                      rows: 1,
                    }}
                    style={{ width: '300px' }}
                  />
                  <Skeleton.Input
                    active
                    block={true}
                    size={240}
                    style={{ width: '100%', marginBottom: '58.5px', marginTop: '16px' }}
                  />
                </>
              ) : (
                <>
                  {isCustomer ? (
                    <Tooltip
                      title={
                        <span style={{ fontSize: 12 }}>
                          The total amount of money that was paid through Bujeti issued
                          accounts.
                          <br />
                          Invoices marked as paid are not taken into count.
                        </span>
                      }
                    >
                      <h5
                        className="d-flex align-items-center gap-1"
                        style={{ width: 'fit-content' }}
                      >
                        Total collected
                        <InfoCircleOutlined
                          style={{ fontSize: '14px', paddingTop: '2px' }}
                        />
                      </h5>
                    </Tooltip>
                  ) : (
                    <h5>Total spent </h5>
                  )}

                  <h3>₦{formatNumThousandSeparator(chartData?.totalSpent / 100 || 0)}</h3>
                  <div className="divider"></div>
                  <div
                    style={{ height: '240px', marginBottom: '32px' }}
                    className="overflow-x-auto"
                  >
                    <ProfileChart isCustomer={isCustomer} data={chartData?.data ?? []} />
                  </div>
                </>
              ))}
            <ProfileTabs
              loading={loading}
              data={analytics}
              informationBasedOnUserType={informationBasedOnUserType}
            />
          </Col>
          <Col xl={4} className="py-4">
            <ProfileInformation
              isCustomer={isCustomer}
              basicInformation={basicInformation}
              bankInformation={bankInformation}
              userInformation={userInformation}
              onSendFunds={() => handleSendFunds()}
              onEdit={onEdit}
              onDelete={onDelete}
              loading={loading}
              deleteText={deleteText}
              sendText={sendText}
            />
          </Col>
        </Row>
      </section>
      {showSendFunds && (
        <BujetiPayModal
          recipientCode={userInformation.code}
          setOpenModal={setShowSendFunds}
        />
      )}
      {children}
    </Container>
  );
};
export default ProfileComponent;
