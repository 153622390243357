import AccountStatement from 'pages/AccountStatement';
import BatchPayment from 'pages/BatchPayment';
import BvnRequest from 'pages/Bvn-request';
import Cards from 'pages/Cards';
import Collections from 'pages/Collections';
import Dashboard from 'pages/Dashboard';
import Expenses from 'pages/Expenses';
import FundPay from 'pages/FundPay/FundPay';
import CreateInvoice from 'pages/Invoices/CreateInvoice';
import InvoiceDetails from 'pages/Invoices/CustomerInvoice';
import { PDF } from 'pages/Invoices/components/InvoicePdf';
import BusinessOwner from 'pages/OnboardingInvite';
import PageNotFound from 'pages/PageNotFound';
import People from 'pages/People';
import Referrals from 'pages/Referrals';
import Requests from 'pages/Requests';
import Reimbursements from 'pages/Reimbursement';
import Settings from 'pages/Settings';
import ChangeBillingDetails from 'pages/Settings/Billings/ChangeBillingDetails';
import Checkout from 'pages/Settings/Billings/Checkout';
import SelectedTeams from 'pages/Teams/SelectedTeams/SelectedTeams';
import Transactions from 'pages/Transactions';
import EditUser from 'pages/account/Edit';
import PasswordRequest from 'pages/auth/PasswordRequest';
import MultiEntity from 'pages/auth/MultiEntity';
import ResetPassword from 'pages/auth/ResetPassword';
import ResetRequest from 'pages/auth/ResetRequest';
import Cash from 'pages/Cash';
import ViewAccount from 'pages/Cash/ViewAccount';
import Report from 'pages/Reports';
import Pay from 'pages/pay';
import MyDocument from 'pages/receipt';
import Beneficiaries from './pages/Beneficiaries';
import SelectedBudgets from './pages/Budgets/SelectedBudgets/SelectedBudgets';
import Profile from './pages/Profile';
import ExampleEmptyState from 'pages/ExampleEmptyState';
import SubAccountView from 'pages/Cash/SubAccounts/SubAccountView';
import BulkMemberCreation from 'pages/Beneficiaries/BulkMemberCreation';
import ApprovalRuleCreation from 'pages/Approvals/ApprovalRuleCreation';
import MemberProfile from 'pages/Profile/MemberProfile';
import VendorProfile from 'pages/Profile/VendorProfile';
import Policy from 'pages/Policy';
import CreatePolicy from 'pages/Policy/CreatePolicy';
import Plans from 'pages/Settings/Plans';
import Billings from 'pages/Settings/Billings';
import { BillingPDF } from 'pages/Settings/Billings/BillingInvoicePdf';
import CustomerProfile from 'pages/Profile/CustomerProfile';
import Login from 'pages/Authentication/Login';
import Register from 'pages/Authentication/Register';
import VerifyOTP from 'pages/Authentication/VerifyOTP';
import AddMultipleCustomers from 'pages/Customers/AddMultipleCustomers';
import Compliances from 'pages/Compliances';
import Customers from 'pages/Customers';
import FindCompany from 'pages/Authentication/FindCompany';
import CompanySolution from 'pages/Authentication/CompanySolution/CompanySolution';
import Onboarding from 'pages/Onboarding';
import BusinessKyc from 'pages/Onboarding/business-kyc';
import Category from 'pages/Category';
import CreateCategory from 'pages/Category/CreateCategory';
import CreateSubCategory from 'pages/Category/CreateSubCategory';
import ViewCategory from 'pages/Category/ViewCategory';
import UploadMultipleCategories from 'pages/Category/UploadMultipleCategories';
import CreateMultipleCategories from 'pages/Category/CreateMultipleCategories';
import CreateCategorizationRules from 'pages/Category/CreateCategorizationRules';
import ImportVendors from 'pages/Vendor/ImportVendors';
import VerifyVendorOTP from 'pages/Vendor/VerifyOTP';
import InvitedVendorsForm from 'pages/Vendor/InvitedVendorsForm';
import BatchApproval from 'pages/BatchPayment/BatchApproval';

import InvitedDirectorForm from 'pages/OnboardingInvite';
import EmployeeOnboarding from 'pages/Onboarding/employeeOnboarding';
import Budgets from 'pages/Budgets';
import Vendor from 'pages/Vendor';
import EditMultipleCategories from 'pages/Category/EditMultipleCategories';
import SubscriptionPage from 'pages/Profile/SubscriptionPage';
import Bills from 'pages/Bills';
import CreateBill from 'pages/Bills/components/CreateBill';
import ReviewBill from 'pages/Bills/components/ReviewBill';

export default [
  {
    exact: true,
    path: '/emptystate',
    component: ExampleEmptyState,
    isPrivate: true,
  },

  {
    exact: true,
    path: '/get-started/business-kyc',
    name: 'Onboarding',
    component: BusinessKyc,
    isPrivate: true,
    // permissions: ['dash-*', 'dash-view', 'dash-edit'],
  },

  {
    exact: true,
    path: '/get-started/admin-kyb',
    name: 'Onboarding',
    component: EmployeeOnboarding,
    isPrivate: true,
    // permissions: ['dash-*', 'dash-view', 'dash-edit'],
  },

  {
    exact: true,
    path: '/get-started',
    name: 'get-started',
    component: Onboarding,
    isPrivate: true,
    // permissions: ['dash-*', 'dash-view', 'dash-edit'],
  },

  {
    exact: true,
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    isPrivate: true,
    // permissions: ['dash-*', 'dash-view', 'dash-edit'],
  },

  {
    exact: true,
    path: '/accounts',
    name: 'Cash',
    component: Cash,
    isPrivate: true,
    // permissions: ['dash-*', 'dash-view', 'dash-edit'],
  },
  {
    exact: true,
    path: '/accounts/:accountCode',
    name: 'Account',
    component: ViewAccount,
    isPrivate: true,
    permissions: ['dash-*', 'dash-view', 'dash-edit'],
  },
  {
    exact: true,
    path: '/reports',
    name: 'Reports',
    component: Report,
    isPrivate: true,
    permissions: ['dash-*', 'dash-view', 'dash-edit'],
  },
  {
    exact: true,
    path: '/accounts/:id',
    name: 'Cash',
    component: Cash,
    isPrivate: true,
    // permissions: ['dash-*', 'dash-view', 'dash-edit'],
  },
  {
    exact: true,
    path: '/accounts/subaccounts/:subAccountCode',
    name: 'SubAccountView',
    component: SubAccountView,
    isPrivate: true,
  },
  {
    exact: true,
    path: '/accounts/subaccounts/:subAccountCode/transactions',
    name: 'SubAccountView',
    component: SubAccountView,
    isPrivate: true,
  },
  {
    exact: true,
    path: '/accounts/subaccounts/:subAccountCode/members',
    name: 'SubAccountView',
    component: SubAccountView,
    isPrivate: true,
  },
  {
    exact: true,
    path: '/accounts/subaccounts/:subAccountCode/cards',
    name: 'SubAccountView',
    component: SubAccountView,
    isPrivate: true,
  },
  {
    exact: true,
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    exact: true,
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    exact: true,
    path: '/switch-company',
    name: 'MultiEntity',
    component: MultiEntity,
    isPrivate: true,
    headerHide: true,
  },
  {
    exact: true,
    path: '/switch-account',
    name: 'MultiEntity',
    component: MultiEntity,
    isPrivate: true,
    headerHide: true,
  },
  {
    exact: true,
    path: '/new-subsidiary',
    name: 'NewSubsidiary',
    component: MultiEntity,
    isPrivate: true,
    headerHide: true,
  },
  {
    exact: true,
    path: '/new-company',
    name: 'NewCompany',
    component: MultiEntity,
    isPrivate: true,
    headerHide: true,
  },
  {
    exact: true,
    path: '/verification',
    name: 'VerifyOTP',
    component: VerifyOTP,
  },
  {
    exact: true,
    path: '/forgot-password',
    name: 'Forgot Password',
    component: PasswordRequest,
  },
  {
    exact: true,
    path: '/reset_link/:hashVal',
    name: 'Reset Password',
    component: ResetPassword,
  },
  {
    exact: true,
    path: '/reset-request',
    name: 'Reset Request',
    component: ResetRequest,
  },

  {
    exact: true,
    path: '/find-company',
    name: 'FindCompany',
    component: FindCompany,
    isPrivate: true,
    headerHide: true,
  },

  {
    exact: true,
    path: '/company-solution',
    name: 'CompanySolution',
    component: CompanySolution,
    isPrivate: true,
    headerHide: true,
  },

  {
    exact: true,
    path: '/bvn-request/:redisKey',
    name: 'Bvn-request',
    component: BvnRequest,
    headerHide: true,
  },
  {
    exact: true,
    path: '/bvn-request/:redisKey/:type',
    name: 'Bvn-request',
    component: BvnRequest,
    headerHide: true,
  },
  {
    exact: true,
    path: '/complete-onboarding/:code',
    name: 'InvitedDirectorForm',
    component: InvitedDirectorForm,
    headerHide: true,
  },
  {
    exact: true,
    path: '/user/edit',
    name: 'EditUser',
    isPrivate: true,
    headerHide: true,
    component: EditUser,
  },
  {
    exact: true,
    path: '/pay',
    name: 'Pay Now',
    component: FundPay,
    headerHide: true,
    isPrivate: true,
  },
  {
    exact: true,
    path: '/expenses',
    redirect: '/expenses/budgets',
    name: 'Compliances',
    component: Expenses,
    isPrivate: true,
    permissions: ['budget-*', 'budget-view', 'budget-edit', 'budget-create'],
  },
  {
    exact: true,
    path: '/expenses/budgets',
    name: 'Compliances',
    component: Budgets,
    isPrivate: true,
    permissions: ['budget-*', 'budget-view', 'budget-edit', 'budget-create'],
  },

  {
    exact: true,
    path: '/expenses/bills',
    name: 'Bills',
    component: Bills,
    isPrivate: true,
  },

  {
    exact: true,
    path: '/expenses/bills/requests',
    name: 'PendingBillRequests',
    component: Bills,
    isPrivate: true,
    isTabRoute: true,
  },

  {
    exact: true,
    path: '/expenses/bills/drafts',
    name: 'DraftBills',
    component: Bills,
    isPrivate: true,
    isTabRoute: true,
  },

  {
    exact: true,
    path: '/expenses/bills/scheduled',
    name: 'ScheduledBills',
    component: Bills,
    isPrivate: true,
    isTabRoute: true,
  },

  {
    exact: true,
    path: '/expenses/bills/archive',
    name: 'ArchivedBills',
    component: Bills,
    isPrivate: true,
    isTabRoute: true,
  },

  {
    exact: true,
    path: '/expenses/bills/create',
    name: 'CreateBill',
    component: CreateBill,
    isPrivate: true,
    headerHide: true,
  },

  {
    exact: true,
    path: '/expenses/bills/review/:code',
    name: 'ReviewBill',
    component: ReviewBill,
    isPrivate: true,
    headerHide: true,
  },

  {
    exact: true,
    path: '/compliances/policies',
    name: 'compliances',
    component: Policy,
    isPrivate: true,
    permissions: ['policy-*', 'policy-view', 'policy-edit', 'policy-create'],
  },
  {
    exact: true,
    path: '/compliances/categories',
    name: 'compliances',
    component: Category,
    isPrivate: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/compliances/categories/:categoryCode/details',
    name: 'Compliances',
    component: ViewCategory,
    isPrivate: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/compliances/categories/create',
    name: 'Compliances',
    component: CreateCategory,
    isPrivate: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/compliances/categories/:categoryCode/overview',
    name: 'Compliances',
    component: ViewCategory,
    isPrivate: true,
    isTabRoute: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/compliances/categories/:categoryCode/transactions',
    name: 'Compliances',
    component: ViewCategory,
    isPrivate: true,
    isTabRoute: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/compliances/categories/:categoryCode/subcategories',
    name: 'Compliances',
    component: ViewCategory,
    isPrivate: true,
    isTabRoute: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/compliances/categories/:categoryCode/approval-rules',
    name: 'Compliances',
    component: ViewCategory,
    isPrivate: true,
    isTabRoute: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/compliances/categories/:categoryCode/policies',
    name: 'Compliances',
    component: ViewCategory,
    isPrivate: true,
    isTabRoute: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/compliances/categories/:categoryCode/policies/create',
    name: 'Compliances',
    component: CreatePolicy,
    isPrivate: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/compliances/categories/:categoryCode/approval-rules/create',
    name: 'Compliances',
    component: ApprovalRuleCreation,
    isPrivate: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/compliances/categories/:categoryCode/approval-rules/edit/:ruleCode',
    name: 'Compliances',
    component: ApprovalRuleCreation,
    isPrivate: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/compliances/categories/multiple/upload',
    name: 'Compliances',
    component: UploadMultipleCategories,
    isPrivate: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/compliances/categories/multiple/create',
    name: 'Compliances',
    component: CreateMultipleCategories,
    isPrivate: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/compliances/categories/multiple/edit',
    name: 'Compliances',
    component: EditMultipleCategories,
    isPrivate: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/compliances/categories/multiple/create',
    name: 'Compliances',
    component: CreateMultipleCategories,
    isPrivate: true,
    permissions: ['policy-*', 'policy-view', 'policy-edit', 'policy-create'],
  },
  {
    exact: true,
    path: '/compliances/categories/subcategories/create',
    name: 'Compliances',
    component: CreateSubCategory,
    isPrivate: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/compliances/categories/:categoryCode/categorization-rules',
    name: 'Compliances',
    component: ViewCategory,
    isPrivate: true,
    isTabRoute: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/compliances/categories/:categoryCode/categorization-rules/create',
    name: 'Compliances',
    component: CreateCategorizationRules,
    isPrivate: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/compliances/policies/create',
    name: 'compliances',
    component: CreatePolicy,
    isPrivate: true,
    permissions: ['policy-*', 'policy-view', 'policy-edit', 'policy-create'],
  },
  {
    exact: true,
    path: '/compliances/policies/:policyCode',
    name: 'compliances',
    component: Policy,
    isPrivate: true,
  },
  {
    exact: true,
    path: '/expenses/vendors/profile/:id',
    name: 'Compliances',
    component: VendorProfile,
    isPrivate: true,
    permissions: ['vendor-*', 'vendor-view', 'vendor-edit', 'vendor-create'],
  },
  {
    exact: true,
    path: '/expenses/vendors',
    name: 'Compliances',
    component: Vendor,
    isTabRoute: true,
    isPrivate: true,
    permissions: ['vendor-*', 'vendor-view', 'vendor-edit', 'vendor-create'],
  },
  {
    exact: true,
    path: '/expenses/vendors/import',
    name: 'Expenses',
    component: ImportVendors,
    isPrivate: true,
    permissions: ['vendor-*', 'vendor-create'],
  },
  {
    exact: true,
    path: '/invite/:code',
    name: 'vendor-verifyOTP',
    component: VerifyVendorOTP,
  },
  {
    exact: true,
    path: '/complete-profile',
    name: 'vendor-complete-profile',
    isPrivate: false,
    component: InvitedVendorsForm,
  },
  {
    exact: true,
    path: '/expenses/budgets/:id',
    name: 'SelectedBudgets',
    component: SelectedBudgets,
    isPrivate: true,
    permissions: ['budget-*', 'budget-view', 'budget-edit', 'budget-create'],
  },
  {
    exact: true,
    path: '/expenses/budgets/:budgetId/overview',
    name: 'SelectedBudgets',
    component: SelectedBudgets,
    isPrivate: true,
    permissions: ['budget-*', 'budget-view', 'budget-edit', 'budget-create'],
  },
  {
    exact: true,
    path: '/expenses/budgets/:budgetId/beneficiaries',
    name: 'SelectedBudgets',
    component: SelectedBudgets,
    isPrivate: true,
    permissions: [
      'budget-*',
      'employee-*',
      'employee-view',
      'employee-edit',
      'employee-create',
    ],
  },
  {
    exact: true,
    path: '/expenses/budgets/:budgetId/cards',
    name: 'SelectedBudgets',
    component: SelectedBudgets,
    isPrivate: true,
    permissions: ['budget-*', 'card-*', 'card-view', 'card-edit', 'card-create'],
  },
  {
    exact: true,
    path: '/expenses/budgets/:budgetId/transactions',
    name: 'SelectedBudgets',
    component: SelectedBudgets,
    isPrivate: true,
    permissions: [
      'budget-*',
      'transaction-*',
      'transaction-view',
      'transaction-edit',
      'transaction-create',
    ],
  },
  // {
  //   exact: true,
  //   path: '/expenses/budgets/:budgetId/policies',
  //   name: 'SelectedBudgets',
  //   component: SelectedBudgets,
  //   isPrivate: true,
  //   permissions: ['budget-*', 'policy-*', 'policy-view', 'policy-edit', 'policy-create'],
  // },
  {
    exact: true,
    path: '/receipt',
    name: 'Receipt',
    component: MyDocument,
    isPrivate: false,
  },
  {
    exact: true,
    path: '/beneficiaries',
    name: 'Dashboard',
    component: Beneficiaries,
    isPrivate: true,
    permissions: ['employee-*', 'employee-view', 'employee-edit', 'employee-create'],
  },
  {
    exact: true,
    path: '/transactions',
    name: 'Transactions',
    redirect: '/transactions/payments',
    component: Transactions,
    isPrivate: true,
    permissions: [
      'transaction-*',
      'transaction-view',
      'transaction-edit',
      'transaction-create',
      'batch-transaction-*',
      'batch-transaction-view',
      'batch-transaction-edit',
      'batch-transaction-create',
    ],
  },
  {
    exact: true,
    path: '/transactions/:transactionCode/details',
    name: 'Transactions',
    component: Transactions,
    isPrivate: true,
    permissions: [
      'transaction-*',
      'transaction-view',
      'transaction-edit',
      'transaction-create',
      'batch-transaction-*',
      'batch-transaction-view',
      'batch-transaction-edit',
      'batch-transaction-create',
    ],
  },
  {
    exact: true,
    path: '/transactions/payments',
    name: 'Transactions',
    component: Transactions,
    isPrivate: true,
    permissions: [
      'transaction-*',
      'transaction-view',
      'transaction-edit',
      'transaction-create',
      'batch-transaction-*',
      'batch-transaction-view',
      'batch-transaction-edit',
      'batch-transaction-create',
    ],
  },
  {
    exact: true,
    path: '/transactions/payments/:transactionCode/details',
    name: 'Transactions',
    component: Transactions,
    isPrivate: true,
    permissions: [
      'transaction-*',
      'transaction-view',
      'transaction-edit',
      'transaction-create',
      'batch-transaction-*',
      'batch-transaction-view',
      'batch-transaction-edit',
      'batch-transaction-create',
    ],
  },
  {
    exact: true,
    path: '/transactions/scheduled',
    name: 'Transactions',
    component: Transactions,
    isTabRoute: true,
    isPrivate: true,
    permissions: [
      'transaction-*',
      'transaction-view',
      'transaction-edit',
      'transaction-create',
      'batch-transaction-*',
      'batch-transaction-view',
      'batch-transaction-edit',
      'batch-transaction-create',
    ],
  },
  {
    exact: true,
    path: '/transactions/requests',
    name: 'Transactions',
    component: Transactions,
    isTabRoute: true,
    isPrivate: true,
    permissions: [
      'transaction-*',
      'transaction-view',
      'transaction-edit',
      'transaction-create',
      'batch-transaction-*',
      'batch-transaction-view',
      'batch-transaction-edit',
      'batch-transaction-create',
    ],
  },
  {
    exact: true,
    path: '/transactions/requests/:requestCode/approve',
    name: 'Transactions',
    component: Transactions,
    isPrivate: true,
    permissions: [
      'transaction-*',
      'transaction-view',
      'transaction-edit',
      'transaction-create',
      'batch-transaction-*',
      'batch-transaction-view',
      'batch-transaction-edit',
      'batch-transaction-create',
    ],
  },
  {
    exact: true,
    path: '/transactions/requests/:requestCode/decline',
    name: 'Transactions',
    component: Transactions,
    isPrivate: true,
    permissions: [
      'transaction-*',
      'transaction-view',
      'transaction-edit',
      'transaction-create',
      'batch-transaction-*',
      'batch-transaction-view',
      'batch-transaction-edit',
      'batch-transaction-create',
    ],
  },
  {
    exact: true,
    path: '/transactions/requests/:requestCode/details',
    name: 'Transactions',
    component: Transactions,
    isPrivate: true,
    permissions: [
      'transaction-*',
      'transaction-view',
      'transaction-edit',
      'transaction-create',
      'batch-transaction-*',
      'batch-transaction-view',
      'batch-transaction-edit',
      'batch-transaction-create',
    ],
  },
  {
    exact: true,
    path: '/compliances/rules/create',
    name: 'Compliances',
    component: ApprovalRuleCreation,
    isPrivate: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/compliances/rules/edit/:ruleCode',
    name: 'Transactions',
    component: ApprovalRuleCreation,
    isPrivate: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/compliances/rules',
    name: 'Transactions',
    component: Compliances,
    isPrivate: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/compliances/rules/:code',
    name: 'Compliances',
    component: Compliances,
    isPrivate: true,
    permissions: [
      'transaction-*',
      'transaction-view',
      'transaction-edit',
      'transaction-create',
    ],
  },
  {
    exact: true,
    path: '/transactions/batch',
    name: 'Transactions',
    isTabRoute: true,
    component: Transactions,
    isPrivate: true,
    permissions: [
      'batch-transaction-*',
      'batch-transaction-view',
      'batch-transaction-edit',
      'batch-transaction-create',
    ],
  },

  {
    exact: true,
    path: '/transactions/batch/:code',
    name: 'Transactions',
    component: BatchApproval,
    isPrivate: true,
    permissions: [
      'batch-transaction-*',
      'batch-transaction-view',
      'batch-transaction-edit',
      'batch-transaction-create',
    ],
  },

  {
    exact: true,
    path: '/transactions/bulk',
    name: 'Transactions',
    component: Transactions,
    isPrivate: true,
    permissions: [
      'batch-transaction-*',
      'batch-transaction-view',
      'batch-transaction-edit',
      'batch-transaction-create',
    ],
  },

  {
    exact: true,
    path: '/transactions/pending',
    name: 'Transactions',
    component: Transactions,
    isPrivate: true,
    permissions: [
      'transaction-*',
      'transaction-view',
      'transaction-edit',
      'transaction-create',
      'batch-transaction-*',
      'batch-transaction-view',
      'batch-transaction-edit',
      'batch-transaction-create',
    ],
  },

  {
    exact: true,
    path: '/transactions/batch-payment',
    name: 'Transactions',
    component: BatchPayment,
    isPrivate: true,
    permissions: [
      'batch-transaction-*',
      'batch-transaction-view',
      'batch-transaction-edit',
      'batch-transaction-create',
    ],
  },
  {
    exact: true,
    path: '/settings',
    name: 'Settings',
    component: Settings,
    isPrivate: true,
    permissions: [
      'org-*',
      'org-edit',
      'settlement-*',
      'settlement-view',
      'settlement-edit',
      'settlement-create',
      'integration-*',
      'integration-view',
      'integration-edit',
      'integration-create',
    ],
  },
  {
    exact: true,
    path: '/settings/settlement-account',
    name: 'Settings',
    component: Settings,
    isPrivate: true,
    isTabRoute: true,
    permissions: [
      'org-*',
      'org-edit',
      'settlement-*',
      'settlement-view',
      'settlement-edit',
      'settlement-create',
    ],
  },
  {
    exact: true,
    path: '/settings/integrations',
    name: 'Settings',
    component: Settings,
    isPrivate: true,
    isTabRoute: true,
    permissions: [
      'org-*',
      'org-edit',
      'integration-*',
      'integration-view',
      'integration-edit',
      'integration-create',
    ],
  },
  {
    exat: true,
    path: '/settings/billings',
    name: 'Settings',
    component: Settings,
    isPrivate: true,
    isTabRoute: true,
  },
  {
    exat: true,
    path: '/settings/plans',
    name: 'Settings',
    component: Settings,
    isPrivate: true,
    isTabRoute: true,
  },
  {
    exat: true,
    path: '/settings/checkout/:planCode/:planType',
    name: 'Checkout',
    component: Checkout,
    isPrivate: true,
  },
  {
    exact: true,
    path: '/settings/billings/address',
    name: 'Settings',
    component: ChangeBillingDetails,
    isPrivate: true,
  },
  {
    exact: true,
    path: '/cards',
    name: 'Cards',
    component: Cards,
    isPrivate: true,
    permissions: ['card-*', 'card-view', 'card-edit', 'card-create'],
  },
  {
    exact: true,
    path: '/cards/all-cards',
    name: 'Cards',
    component: Cards,
    isPrivate: true,
    isTabRoute: true,
    permissions: ['card-*', 'card-view', 'card-edit', 'card-create'],
  },
  {
    exact: true,
    path: '/cards/subscriptions',
    name: 'Cards',
    component: Cards,
    isPrivate: true,
    isTabRoute: true,
    permissions: ['card-*', 'card-view', 'card-edit', 'card-create'],
  },
  {
    exact: true,
    path: '/cards/subscriptions/:code',
    name: 'Cards',
    component: SubscriptionPage,
    isPrivate: true,
    isTabRoute: true,
    permissions: ['card-*', 'card-view', 'card-edit', 'card-create'],
  },
  {
    exact: true,
    path: '/cards/requested-cards',
    name: 'Cards',
    component: Cards,
    isPrivate: true,
    isTabRoute: true,
    permissions: ['card-*', 'card-view', 'card-edit', 'card-create'],
  },

  {
    exact: true,
    path: '/teams/people',
    name: 'Team management',
    component: People,
    isPrivate: true,
    permissions: [
      'employee-*',
      'employee-view',
      'employee-edit',
      'employee-create',
      'team-*',
      'team-view',
      'team-edit',
      'team-create',
      'role-*',
      'role-view',
      'role-edit',
      'role-create',
    ],
  },
  {
    exact: true,
    path: '/teams',
    name: 'Team management',
    component: People,
    isPrivate: true,
    isTabRoute: true,
    permissions: ['team-*', 'team-view', 'team-edit', 'team-create'],
  },
  {
    exact: true,
    path: '/teams/members/profile/:id',
    name: 'Profile',
    component: MemberProfile,
    isPrivate: true,
    permissions: ['employee-*', 'employee-view', 'employee-edit', 'employee-create'],
  },
  {
    exact: true,
    path: '/teams/roles',
    name: 'Team management',
    component: People,
    isPrivate: true,
    isTabRoute: true,
    permissions: ['role-*', 'role-view', 'role-edit', 'role-create'],
  },
  {
    exact: true,
    path: '/teams/members/create',
    name: 'Team management',
    component: People,
    isPrivate: true,
    permissions: ['employee-*', 'employee-create'],
  },
  {
    exact: true,
    path: '/teams/members/upload',
    name: 'People',
    component: People,
    isPrivate: true,
    permissions: ['employee-*', 'employee-create'],
  },
  {
    exact: true,
    path: '/teams/:id',
    name: 'SelectedTeams',
    component: SelectedTeams,
    isPrivate: true,
    permissions: ['team-*', 'team-view', 'team-edit', 'team-create'],
  },
  {
    exact: true,
    path: '/teams/:teamsId/overview',
    name: 'SelectedTeams',
    component: SelectedTeams,
    isPrivate: true,
    permissions: ['team-*', 'team-view', 'team-edit', 'team-create'],
  },
  {
    exact: true,
    path: '/teams/:teamsId/members',
    name: 'SelectedTeams',
    component: SelectedTeams,
    isPrivate: true,
    permissions: ['team-*', 'team-view', 'team-edit', 'team-create'],
  },
  {
    exact: true,
    path: '/teams/:teamsId/budgets',
    name: 'SelectedTeams',
    component: SelectedTeams,
    isPrivate: true,
    permissions: ['team-*', 'team-view', 'team-edit', 'team-create', 'budget-view'],
  },
  {
    exact: true,
    path: '/teams/:teamsId/cards',
    name: 'SelectedTeams',
    component: SelectedTeams,
    isPrivate: true,
    permissions: ['team-*', 'team-view', 'team-edit', 'team-create', 'card-view'],
  },
  {
    exact: true,
    path: '/teams/:teamsId/transactions',
    name: 'SelectedTeams',
    component: SelectedTeams,
    isPrivate: true,
    permissions: ['team-*', 'team-view', 'team-edit', 'team-create', 'transaction-view'],
  },
  {
    exact: true,
    path: '/teams/:teamsId/reimbursements',
    name: 'SelectedTeams',
    component: SelectedTeams,
    isPrivate: true,
    permissions: [
      'team-*',
      'team-view',
      'team-edit',
      'team-create',
      'reimbursement-view',
    ],
  },
  {
    exact: true,
    path: '/requests/reimbursements',
    name: 'Reimbursement',
    component: Requests,
    isTabRoute: true,
    isPrivate: true,
    permissions: [
      'reimbursement-*',
      'reimbursement-view',
      'reimbursement-edit',
      'reimbursement-create',
    ],
  },
  {
    exact: true,
    path: '/requests/reimbursements',
    name: 'Reimbursement',
    component: Requests,
    isPrivate: true,
    permissions: [
      'reimbursement-*',
      'reimbursement-view',
      'reimbursement-edit',
      'reimbursement-create',
    ],
  },
  {
    exact: true,
    path: '/requests/reimbursements/:reimbursementCode/approve',
    name: 'Reimbursement',
    component: Requests,
    isPrivate: true,
    permissions: [
      'reimbursement-*',
      'reimbursement-view',
      'reimbursement-edit',
      'reimbursement-create',
    ],
  },
  {
    exact: true,
    path: '/requests/reimbursements/:reimbursementCode/decline',
    name: 'Reimbursement',
    component: Requests,
    isPrivate: true,
    permissions: [
      'reimbursement-*',
      'reimbursement-view',
      'reimbursement-edit',
      'reimbursement-create',
    ],
  },
  {
    exact: true,
    path: '/requests/reimbursements/:reimbursementCode',
    name: 'Reimbursement',
    component: Requests,
    isPrivate: true,
    permissions: [
      'reimbursement-*',
      'reimbursement-view',
      'reimbursement-edit',
      'reimbursement-create',
    ],
  },

  {
    exact: true,
    path: '/requests/payments',
    name: 'Payments',
    component: Requests,
    isPrivate: true,
    permissions: [
      'transaction-*',
      'transaction-view',
      'transaction-edit',
      'transaction-create',
      'batch-transaction-*',
      'batch-transaction-view',
      'batch-transaction-edit',
      'batch-transaction-create',
    ],
  },
  {
    exact: true,
    path: '/requests/payments/:requestCode/approve',
    name: 'Payments',
    component: Requests,
    isPrivate: true,
    permissions: [
      'transaction-*',
      'transaction-view',
      'transaction-edit',
      'transaction-create',
      'batch-transaction-*',
      'batch-transaction-view',
      'batch-transaction-edit',
      'batch-transaction-create',
    ],
  },
  {
    exact: true,
    path: '/requests/payments/:requestCode/decline',
    name: 'Payments',
    component: Requests,
    isPrivate: true,
    permissions: [
      'transaction-*',
      'transaction-view',
      'transaction-edit',
      'transaction-create',
      'batch-transaction-*',
      'batch-transaction-view',
      'batch-transaction-edit',
      'batch-transaction-create',
    ],
  },
  {
    exact: true,
    path: '/requests/payments/:requestCode/details',
    name: 'Payments',
    component: Requests,
    isPrivate: true,
    permissions: [
      'transaction-*',
      'transaction-view',
      'transaction-edit',
      'transaction-create',
      'batch-transaction-*',
      'batch-transaction-view',
      'batch-transaction-edit',
      'batch-transaction-create',
    ],
  },

  {
    exact: true,
    path: '/requests/funds',
    name: 'FundRequests',
    component: Requests,
    isTabRoute: true,
    isPrivate: true,
    permissions: [
      'reimbursement-*',
      'reimbursement-view',
      'reimbursement-edit',
      'reimbursement-create',
    ],
  },
  {
    exact: true,
    path: '/requests/funds/:requestCode/approve',
    name: 'Funds',
    component: Requests,
    isPrivate: true,
    permissions: [
      'reimbursement-*',
      'reimbursement-view',
      'reimbursement-edit',
      'reimbursement-create',
    ],
  },
  {
    exact: true,
    path: '/requests/funds/:requestCode/decline',
    name: 'Funds',
    component: Requests,
    isPrivate: true,
    permissions: [
      'reimbursement-*',
      'reimbursement-view',
      'reimbursement-edit',
      'reimbursement-create',
    ],
  },
  {
    exact: true,
    path: '/requests/funds/:requestCode',
    name: 'Funds',
    component: Requests,
    isPrivate: true,
    permissions: [
      'reimbursement-*',
      'reimbursement-view',
      'reimbursement-edit',
      'reimbursement-create',
    ],
  },
  {
    exact: true,
    path: '/requests/rules/create',
    name: 'Rules',
    component: ApprovalRuleCreation,
    isPrivate: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/requests/rules/edit/:ruleCode',
    name: 'Rules',
    component: ApprovalRuleCreation,
    isPrivate: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/requests/rules/:ruleCode',
    name: 'Rules',
    component: Requests,
    isPrivate: true,
    permissions: [
      'transaction-*',
      'transaction-view',
      'transaction-edit',
      'transaction-create',
    ],
  },
  {
    exact: true,
    path: '/compliances/rules',
    name: 'Rules',
    component: Requests,
    isPrivate: true,
    permissions: ['org-*', 'org-view'],
  },
  {
    exact: true,
    path: '/settings/profile',
    name: 'Settings',
    component: Settings,
    isPrivate: true,
  },
  {
    exact: true,
    path: '/settings/company',
    name: 'Settings',
    component: Settings,
    isPrivate: true,
    isTabRoute: true,
  },
  {
    exact: true,
    path: '/settings/bank',
    name: 'Settings',
    component: Settings,
    isPrivate: true,
    isTabRoute: true,
  },
  {
    exact: true,
    path: '/receivables/invoices',
    name: 'Invoicing',
    component: Collections,
    isPrivate: true,
    permissions: [
      'invoice-*',
      'invoice-view',
      'invoice-edit',
      'invoice-create',
      'customer-*',
      'customer-view',
      'customer-edit',
      'customer-create',
    ],
  },
  {
    exact: true,
    path: '/receivables/customers',
    name: 'Invoicing',
    component: Customers,
    isPrivate: true,
    permissions: [
      'invoice-*',
      'invoice-view',
      'invoice-edit',
      'invoice-create',
      'customer-*',
      'customer-view',
      'customer-edit',
      'customer-create',
    ],
  },
  {
    exact: true,
    path: '/receivables/invoices/:invoiceCode/details',
    name: 'Invoicing',
    component: Collections,
    isPrivate: true,
    permissions: [
      'invoice-*',
      'invoice-view',
      'invoice-edit',
      'invoice-create',
      'customer-*',
      'customer-view',
      'customer-edit',
      'customer-create',
    ],
  },
  {
    exact: true,
    path: '/receivables/invoices/create',
    name: 'Invoicing',
    component: CreateInvoice,
    isPrivate: true,
    permissions: [
      'invoice-*',
      'invoice-view',
      'invoice-edit',
      'invoice-create',
      'customer-*',
      'customer-view',
      'customer-edit',
      'customer-create',
    ],
  },

  {
    exact: true,
    path: '/receivables/recurring-invoices',
    name: 'RecurringInvoices',
    component: Collections,
    isPrivate: true,
    isTabRoute: true,
    permissions: [
      'invoice-*',
      'invoice-view',
      'invoice-edit',
      'invoice-create',
      'customer-*',
      'customer-view',
      'customer-edit',
      'customer-create',
    ],
  },

  {
    exact: true,
    path: '/receivables/requests',
    name: 'PendingRequests',
    component: Collections,
    isPrivate: true,
    isTabRoute: true,
    permissions: [
      'invoice-*',
      'invoice-view',
      'invoice-edit',
      'invoice-create',
      'customer-*',
      'customer-view',
      'customer-edit',
      'customer-create',
    ],
  },

  {
    exact: true,
    path: '/collections/invoices/details/:code',
    name: 'Collections',
    component: InvoiceDetails,
    isPrivate: false,
  },
  {
    exact: true,
    path: '/collections/invoices/pdf',
    name: 'Collections',
    component: BillingPDF,
    // component: PDF,
    isPrivate: false,
  },
  {
    exact: true,
    path: '/receivables/customers',
    name: 'Invoicing',
    component: Collections,
    isPrivate: true,
    permissions: ['customer-*', 'customer-view', 'customer-edit', 'customer-create'],
  },
  {
    exact: true,
    path: '/receivables/customers/create-multiple',
    name: 'Invoicing',
    component: AddMultipleCustomers,
    isPrivate: true,
    permissions: ['customer-*', 'customer-view', 'customer-edit', 'customer-create'],
  },
  {
    exact: true,
    path: '/receivables/customers/profile/:id',
    name: 'Invoicing',
    component: CustomerProfile,
    isPrivate: true,
    permissions: ['customer-*', 'customer-view', 'customer-edit', 'customer-create'],
  },
  {
    exact: true,
    path: '/account-statement',
    name: 'AccountStatement',
    component: AccountStatement,
    isPrivate: true,
    // permissions: ['statement-*', 'statement-view', 'statement-export'],
  },

  {
    exact: true,
    path: '/referrals',
    name: 'referrals',
    component: Referrals,
    isPrivate: true,
  },

  {
    path: '*',
    name: 'Page Not Found',
    component: PageNotFound,
    isPrivate: true,
  },
];
