import OnboardingNoticeDialog from 'components/ConfirmDialog/onboarding-notice';
import NotificationsModal from 'components/Notifications/NotificationsModal';
import Sidebar from 'components/Sidebar';
import { toastError } from 'components/UI/toast';
import BujetiPayModal from 'components/bujetiPayModal';
import ExpenseModal from 'components/bujetiPayModal/expense';
import DefaultAPI from 'pages/DefaultAPI';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, withRouter } from 'react-router-dom';
import { logout } from 'redux/actions/AuthAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import VerifcationRoute from 'utils/VerifcationRoute';
import {
  encryptAndSaveToLocalStorage,
  getUserLandingPage,
  isLoggedIn,
  retrieveAndDecryptFromLocalStorage,
} from 'utils/utility';
import Routes from './Routes';
import classNames from 'classnames';

const renderRoutes = () => {
  const {
    user: { data: { user } = {} },
    loginUser: { data: { user: logedIn } = {} },
  } = useSelector(({ auth }) => auth);

  const { toggle, toggleNotification, toggleOnboardingNotification } = useSelector(
    ({ toggle }) => toggle,
  );

  const { toggleExpense } = useSelector(({ toggleExpense }) => toggleExpense);
  const {
    syncAccountingEntity: {
      error: errorAccountingSoftware,
      data: accountingIntegrationData,
    },
  } = useSelector(({ integrations }) => integrations);

  const dispatch = useDispatch();
  const history = useHistory();

  const { permissions, loading, companyStatus, onboardingStatus, isAdmin } =
    allPermissions();

  const handleLogout = async () => {
    try {
      const { pathname, search, hash } = window.location;
      if (pathname !== '/account-statement') {
        encryptAndSaveToLocalStorage('redirectPath', `${pathname}${search}${hash}`);
        encryptAndSaveToLocalStorage('lastLogin', user?.email);
      }

      dispatch(logout(true));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (errorAccountingSoftware && accountingIntegrationData) {
      if (accountingIntegrationData.openIntegrationPage) {
        history.push('/settings/integrations', {
          platform: accountingIntegrationData.platform,
        });
      }
    }
  }, [errorAccountingSoftware, accountingIntegrationData]);

  const token = retrieveAndDecryptFromLocalStorage('app-session');
  const tokenTime = retrieveAndDecryptFromLocalStorage('exp-app-session');
  const vendorToken = retrieveAndDecryptFromLocalStorage('vendor-app-session');
  const directorToken = retrieveAndDecryptFromLocalStorage('director-app-session');

  const isToken = Boolean(token);

  const authExp = () => {
    return isToken && new Date().getTime() >= +tokenTime;
  };

  const onAction = () => {
    if (authExp()) {
      handleLogout('action');
      // toastError('Session token expired');
    }
  };

  const onIdle = () => {
    if (authExp()) {
      handleLogout('idle');
      toastError("You've been disconnected due to long period of inactivity");
    }
  };

  //Manage session on interaction
  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onAction,
    timeout: 2000,
    throttle: 500,
  });

  const renderRoute = (
    routerProps,
    Component,
    props,
    isPrivate = false,
    isTabRoute = false,
    headerHide,
    routePermission,
    redirect,
    name,
  ) => {
    if (Component) {
      const componentProps = {
        ...routerProps,
        ...props,
      };

      const { pathname: path, state } = routerProps?.location || {};

      if (name === 'vendor-complete-profile' && !vendorToken) {
        return window.open('https://www.bujeti.com/', '_self');
      }

      if (isPrivate) {
        if (authExp()) {
          dispatch(logout(true));
        }

        if (
          routePermission?.length &&
          isLoggedIn() &&
          !hasPermission({ permissions, scopes: routePermission })
        )
          return <Redirect to="/404" />;

        if (!isLoggedIn()) {
          const routeName = ['/', '/login'].includes(path)
            ? '/login'
            : `/login?redirect=${path}`;

          return <Redirect to={routeName} />;
        }

        if (redirect) {
          return <Redirect to={redirect} />;
        }

        return (
          <div className={classNames({ ['section']: !headerHide })}>
            <div
              className={classNames({
                'p-0': headerHide,
                'fade-in': !isTabRoute && !state?.disableFade,
                'main-content': !headerHide,
              })}
            >
              <VerifcationRoute>
                <Component {...componentProps} />
              </VerifcationRoute>
              {toggle && <BujetiPayModal toggle={toggle} />}
              {toggleNotification && (
                <NotificationsModal toggleNotification={toggleNotification} />
              )}
              <Modal
                show={toggleOnboardingNotification}
                centered
                dialogClassName="custom-dialog"
              >
                <OnboardingNoticeDialog />
              </Modal>
              {toggleExpense && <ExpenseModal toggle={toggleExpense} />}
            </div>
          </div>
        );
      }

      return isLoggedIn() && logedIn && !loading ? (
        <Redirect
          to={getUserLandingPage({
            ...logedIn,
            permissions,
            companyStatus,
            onboardingStatus,
            isAdmin,
          })}
        />
      ) : (
        <Component {...componentProps} />
      ); // eslint-disable-line
    }
    return null;
  };

  return Routes.map((route, index) => {
    return (
      <Route
        key={route.name + index}
        exact={route.exact}
        path={route.path}
        render={(routerProps) =>
          renderRoute(
            routerProps,
            route.component,
            route.props,
            route.isPrivate,
            route.isTabRoute,
            route.headerHide,
            route.permissions,
            route.redirect,
            route.name,
          )
        }
      />
    );
  });
};

const Router = () => <Switch>{renderRoutes()}</Switch>;

const findCurrentRoute = (routes, pathname) => {
  for (const route of routes) {
    if (route.path && typeof route.path === 'string' && route.path.trim() !== '*') {
      const pathRegex = new RegExp(`^${route.path.replace(/:\w+/g, '([^/]+)')}$`);
      if (pathRegex.test(pathname)) {
        return route;
      }
    }
  }

  const wildcardRoute = routes.find((route) => route.path === '*');
  if (wildcardRoute) {
    return wildcardRoute;
  }

  return null;
};

const App = () => {
  const history = useHistory();

  const {
    logout: { success },
  } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (success) {
      history.push('/login');
    }
  }, [success]);

  const currentRoute = findCurrentRoute(Routes, location.pathname);
  const { headerHide = false, isPrivate = false } = currentRoute || {};
  const showSidebar = !headerHide && isPrivate;

  return (
    <div className="App">
      <DefaultAPI />

      <div className={classNames({ ['app-layout']: showSidebar })}>
        {showSidebar && (
          <div className="aside">
            <Sidebar />
          </div>
        )}

        <Router />
      </div>
    </div>
  );
};

export default withRouter(App);
