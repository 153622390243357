/* eslint-disable no-console */
import SpaceWrapper from 'components/UI/SpaceWrapper';
import { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getCompany } from 'redux/actions/CompaniesAction';
import { ReactComponent as Avatar } from '../../assets/icons/User.svg';
import { ReactComponent as Bank } from '../../assets/icons/bank.svg';

import { CoinsHand, FileCheck, PieChart, UserPlus } from 'assets/icons';
import AllowedTo, { allPermissions, hasPermission } from 'utils/AllowedTo';
import Onboarding from './Onboarding';
import OnboardingLoader from './Onboarding/Loader';
import Overview from './Overview';
import './styles.scss';

const Dashboard = () => {
  const {
    user,
    userProfile: { success: successUser, loading: loadingUser },
  } = useSelector(({ auth }) => auth);

  const {
    getCompany: { data: companyData = {} },
    switchCompany: { loading: switching },
  } = useSelector(({ companies }) => companies);

  const {
    getBudget: { data: budgetData = {} },
  } = useSelector(({ budgets }) => budgets);

  const {
    getBeneficiaries: { data: beneficiariesData = {} },
    getBeneficiaryBank: { data: getbankData = [] },
  } = useSelector(({ beneficiaries }) => beneficiaries);
  const { permissions, onboardingStatus, companyStatus, isAdmin, userStatus } =
    allPermissions();
  const canViewDash = hasPermission({
    permissions,
    scopes: ['dash-*', 'dash-view', 'dash-edit'],
  });
  const [userName, setUserName] = useState('');
  const [company, setCompany] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [menus, setMenus] = useState([]);
  const [isBankDetailReady, setIsBankDetailsReady] = useState(true);

  const getUserBank = getbankData.find((item) => item.ownerType === 'user');

  const dispatch = useDispatch();

  const isComplete = companyStatus
    ? companyStatus === 'rejected'
    : !!companyData?.businessType;

  const kybcompleted =
    companyData?.dateOfRegistration &&
    companyData?.director?.phoneNumber?.localFormat &&
    companyData?.director?.dob;

  const employeeKycCompleted = user?.data?.user?.dob && user?.data?.user?.address?.street;

  const employeeBankCompleted = getUserBank?.accountName;

  const beneficiarycompleted = beneficiariesData?.beneficiaries?.length > 1;
  const budgetCompleted = budgetData?.budgets?.length > 0;

  useMemo(() => {
    if (isAdmin && ['pending', 'rejected'].includes(onboardingStatus)) {
      setMenus([
        {
          bntName: `${companyData?.director?.dob ? 'Edit details' : 'Complete details'}`,
          icon: <FileCheck />,
          title: 'Complete business details and KYC',
          desc: 'Verify your business identity to ensure secure financial transactions.',
          withAction: false,
          isActive: true,
          isComplete: isComplete,
          step: 1,
        },
        {
          bntName: 'Add member',
          icon: <UserPlus />,
          title: 'Add members',
          desc: 'Invite team members or employees to collaborate efficiently on budget management.',
          withAction: false,
          isActive: false,
          isComplete: false,
          step: 2,
        },
        {
          bntName: 'Create budget',
          icon: <PieChart />,
          title: 'Create your first budget',
          desc: 'Create budgets and allocate funds to departments or projects',
          withAction: false,
          isActive: false,
          isComplete: false,
          step: 3,
        },
        {
          bntName: 'Fund balance',
          icon: <CoinsHand />,
          title: 'Fund your bujeti balance and take a transaction',
          desc: 'Conduct secure bank payments to your vendors, directly from Bujeti.',
          withAction: false,
          isActive: false,
          isComplete: false,
          step: 4,
        },
      ]);
    } else if (
      !isAdmin ||
      (canViewDash && !['pending', 'rejected'].includes(onboardingStatus))
    ) {
      setMenus([
        {
          bntName: 'Add details',
          title: 'Add personal information',
          info: 'Submit your personal details',
          icon: <Avatar />,
          isActive: true,
          isComplete: !!user?.data?.user?.dob,
          step: 1,
        },
        {
          bntName: 'Add bank',
          title: 'Add bank information',
          info: 'Submit your bank account details',
          icon: <Bank />,
          withAction: false,
          isActive: false,
          isComplete: false,
          step: 2,
        },
      ]);
    }
  }, [isAdmin]);

  useEffect(() => {
    if (companyData && companyData.code) setCompany(companyData);
    setAccounts(companyData?.accounts);
  }, [companyData]);

  useEffect(() => {
    if (isAdmin && ['pending', 'rejected'].includes(onboardingStatus)) {
      setIsOnboarding(
        companyStatus && ['pending', 'rejected'].includes(onboardingStatus),
      );
      localStorage.setItem(
        'overviewInfo',
        JSON.stringify({
          businessDetails: {},
          addBeneficiaries: {},
          fundBalance: {},
          firstBudget: {},
        }),
      );
    } else if (
      !isAdmin ||
      (canViewDash && !['pending', 'rejected'].includes(onboardingStatus))
    ) {
      setIsOnboarding(companyStatus && userStatus !== 'active');
      localStorage.setItem(
        'overviewInfo',
        JSON.stringify({
          kycInfo: {},
          bankInfo: {},
        }),
      );
    }
  }, [company]);

  useEffect(() => {
    if (canViewDash && !['pending', 'rejected'].includes(onboardingStatus)) {
      if (!user?.data?.user?.dob && successUser) {
        setIsBankDetailsReady(false);
      } else {
        setIsBankDetailsReady(true);
      }
    }
  }, [successUser, user]);
  const handleOpacity = () => {
    if (isAdmin && ['pending', 'rejected'].includes(onboardingStatus)) {
      let stage = 0;

      if (kybcompleted && !beneficiarycompleted && !budgetCompleted) {
        stage = 25;
      } else if (budgetCompleted && beneficiarycompleted && kybcompleted) {
        stage = 75;
      } else if (
        (beneficiarycompleted || kybcompleted) &&
        (budgetCompleted || kybcompleted)
      ) {
        stage = 50;
      } else if (beneficiarycompleted || budgetCompleted) {
        stage = 25;
      }
      return stage;
    } else if (
      !isAdmin ||
      (canViewDash && !['pending', 'rejected'].includes(onboardingStatus))
    ) {
      let stage = 0;

      if (employeeKycCompleted && employeeBankCompleted) {
        stage = 100;
      } else if (employeeKycCompleted || employeeBankCompleted) {
        stage = 50;
      }

      return stage;
    }
  };

  useEffect(() => {
    const companyCode = user?.data?.user?.company?.code;
    if (user.hasOwnProperty('data')) {
      setUserName(user?.data?.user?.firstName || 'User');
      setCompany(user?.data?.user?.company || {});
    }

    if (!companyData?.code && companyCode) {
      dispatch(
        getCompany({ code: companyCode, includeDocuments: true, includeSteps: true }),
      );
    }
  }, [user]);

  const isLoading = !companyStatus || loadingUser || switching;

  return (
    <div className="dashboard-wrapper">
      {/*//TODO: NotificationModal testing view*/}
      {/* <NotificationModal /> */}
      <Container>
        {/* {!isOnboarding && isBankDetailReady && canViewDash ? ( */}
        <AllowedTo scopes={['dash-*', 'dash-view', 'dash-edit']}>
          <Overview company={company} isLoading={isLoading} accounts={accounts} />
        </AllowedTo>
        {/* ) : (
          <>
            {isLoading ? (
              <OnboardingLoader />
            ) : (
              <SpaceWrapper>
                <Row className="dashboard-section">
                  <Col md={5}>
                    {userName && (
                      <p className="dashboard-title-highlight">
                        <span className="dashboard-user-later fw-bold">
                          {userName.charAt(0)}
                        </span>
                      </p>
                    )}
                    <div className="my-4 dashboard-region">
                      <h2 className="active-text">Welcome, {userName}!</h2>
                      <p className="sub-active-text">
                        {' '}
                        Let’s get you started on Bujeti fast
                      </p>
                    </div>
                    <div className="dashboard-progress__wrapper">
                      <div className="progress__line">
                        <div style={{ width: `${handleOpacity()}%` }} />
                      </div>
                      <span>{handleOpacity()}% progress</span>
                    </div>
                  </Col>
                  <Col md={{ span: 7 }}>
                    <Onboarding menus={menus} setMenus={setMenus} />
                  </Col>
                </Row>
              </SpaceWrapper>
            )}
          </>
        )} */}
      </Container>
    </div>
  );
};
export default Dashboard;
