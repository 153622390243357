import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as LeftIcon } from 'assets/icons/arrow-left.svg';
import ItemsDetails from 'components/bujetiPayModal/ItemsDetails';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import Loading from 'components/UI/Loading';
import Modal from 'components/UI/Modal';
import { toastError } from 'components/UI/toast';
import numeral from 'numeral';
import { useEffect, useMemo, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountSubAccounts, getBalances } from 'redux/actions/BudgetsAction';
import { addFundsToAccount, getPaymentLink } from 'redux/actions/CompaniesAction';
import { calculateFees } from 'redux/actions/FeesAction';
import { getAvailableBalance, getCurrency } from 'utils/helper';

const SwapModal = ({
  isOpen,
  closeHandler,
  defaultSelectedAccount,
  accounts,
  companyCode,
  modalHeading,
}) => {
  if (!isOpen) return <div />;

  const [account, setAccount] = useState(null);
  const [destination, setDestination] = useState(null);
  const [currency, setCurrency] = useState('NGN');
  const [amount, setAmount] = useState(0);
  const [step, setStep] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    getAccountSubAccounts: { data: { balances: subAccounts = [] } = {} },
  } = useSelector(({ budgets }) => budgets);

  const {
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  const balanceOptions = useMemo(() => {
    if (defaultSelectedAccount?.balance) {
      let subAccountCodeList = Array.from(subAccounts, (item) => item.code);
      return getAvailableBalance(balances?.balances ?? [], 32, true, true).filter(
        (item) =>
          item !== null &&
          [defaultSelectedAccount?.balance, ...subAccountCodeList].includes(item.value) &&
          defaultSelectedAccount?.destination !== item.value,
      );
    } else {
      return getAvailableBalance(balances?.balances ?? [], 32, true, true).filter(
        (item) => item !== null,
      );
    }
  }, [balances?.balances, subAccounts, isOpen]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!balances?.balances?.length) dispatch(getBalances());
  }, []);

  useEffect(() => {
    if (defaultSelectedAccount?.balance) {
      dispatch(getAccountSubAccounts({ balanceCode: defaultSelectedAccount?.balance }));
    }
  }, []);

  const setBankAccount = (account) => {
    setAccount(account);
  };

  const {
    calculateFees: { data: feesData, loading: isFeesLoading, success: isFeesSuccess },
  } = useSelector(({ fees }) => fees);

  const {
    getPaymentLink: {
      data: dataPaymentLink,
      loading: loadingPaymentLink,
      success: paymentLinkSuccess,
    },
    addFundsToAccount: { loading: isAddingFunds, success: fundsAdded },
  } = useSelector(({ companies }) => companies);

  const addFunds = (payload) => {
    if (!account?.value) return toastError('Please select a bank account');
    if (!amount) return toastError('Please enter an amount');

    if (account.value === destination)
      return toastError('Source cannot be the same as destination');

    dispatch(addFundsToAccount(payload));
  };

  const initiatePayment = (payload) => {
    dispatch(getPaymentLink({ code: companyCode, payload }));
  };

  const openPayment = () => {
    const payload = {
      currency,
      amount: amount * 100,
      account: account?.value,
      source: account?.value,
      destination: destination.value,
    };
    if (account?.isLinked) initiatePayment(payload);
    else addFunds(payload);
  };

  // handle input state changes
  const handleChange = ({ target: { name, value, validity, rawValue } }) => {
    return setAmount(rawValue);
  };

  const handleNextStep = () => {
    if (!amount) return toastError('Please enter an amount');
    if (+amount <= 0) return toastError('Please enter a valid amount');
    if (!destination?.label) return toastError('Please select a destination account');
    if (!account?.label) return toastError('Please select a source account');

    const checkBalance = account.amount - amount < 0;
    const hasDirectDebit = account?.isDirectDebit;

    if (checkBalance && !hasDirectDebit) {
      return toastError('Available balance is low please topup');
    }

    const data = {
      amount: amount * 100,
      currency: currency,
    };
    dispatch(calculateFees(data));
    setStep(2);
  };

  useEffect(() => {
    if (paymentLinkSuccess || fundsAdded) {
      setIsLoaded(true);

      setAccount(null);
      setDestination(null);
      setCurrency('NGN');
      setAmount(0);

      // dispatch(getCompanies(companyCode));
      // dispatch(getBalances());

      setStep(1);
      closeHandler();
      setIsLoaded(false);

      setAccount(null);
      setDestination(null);
      setCurrency('NGN');
      setAmount(0);
    }
  }, [paymentLinkSuccess, fundsAdded]);

  useEffect(() => {
    if (balances?.balances) {
      let destinationAccount = balances?.balances?.filter(
        (option) => option.code === defaultSelectedAccount?.destination,
      );
      let balanceAccount = balances?.balances?.filter(
        (option) => option.code === defaultSelectedAccount?.balance,
      );
      setDestination(getAvailableBalance(destinationAccount)?.[0], 32, true, true);
      setAccount(getAvailableBalance(balanceAccount)?.[0], 32, true, true);
    }
  }, [defaultSelectedAccount]);

  const goBack = () => {
    setStep(step - 1);
  };

  const payToggleHandler = () => {
    if (step === 2 && !isLoaded) return goBack();

    closeHandler();
    setIsLoaded(false);

    setAccount(null);
    setDestination(null);
    setCurrency('NGN');
    setAmount(0);
  };

  const PreviewCard = () => {
    return (
      <div className="information-wrapper">
        <h2 className="card-title w-100 mb-4">Review your details</h2>
        <div className="form-normal">
          <div className="preview-card__transfer mb-3">
            <div className="px-4 pt-1 pb-2 w-100">
              <ItemsDetails
                className="mb-1"
                title={<span className="header__transfer">Transfer details</span>}
                value={
                  <span
                    className="edit-action__transfer cursor"
                    onClick={payToggleHandler}
                  >
                    Edit
                  </span>
                }
              />

              <ItemsDetails
                title="Total amount we'll send"
                value={`${getCurrency(currency)}${numeral(amount).format('0,0.00')}`}
              />
              {isFeesLoading && <Loading color="#D28B28" size={18} />}
              {!isFeesLoading && feesData && (
                <>
                  <ItemsDetails
                    title="Transaction fees"
                    value={
                      <CurrencyFormat
                        prefix={!feesData.fee ? '' : getCurrency(currency)}
                        value={!feesData.fee ? 'Free' : feesData.fee / 100}
                        displayType="text"
                        isNumericString
                        thousandSeparator={true}
                      />
                    }
                  />

                  {!feesData.fee ? null : (
                    <ItemsDetails
                      className="mt-1"
                      title="Amount"
                      value={
                        <span className="fs-5 text-dark">
                          <CurrencyFormat
                            prefix={getCurrency(currency)}
                            value={Number(feesData.fee / 100 + Number(amount)).toFixed(2)}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </span>
                      }
                    />
                  )}
                </>
              )}

              <hr />

              <ItemsDetails
                className="mb-1"
                title={<span className="header__transfer">Source</span>}
                value={
                  <span
                    className="edit-action__transfer cursor"
                    onClick={payToggleHandler}
                  >
                    Change
                  </span>
                }
              />
              <ItemsDetails title="Account" value={account.label} />

              <hr />

              <ItemsDetails
                className="mb-1"
                title={<span className="header__transfer">Destination</span>}
                value={
                  <span
                    className="edit-action__transfer cursor"
                    onClick={payToggleHandler}
                  >
                    Change
                  </span>
                }
              />
              <ItemsDetails title="Destination" value={destination.label} />
            </div>
          </div>

          <div className="modal-footer border-top">
            <CustomButton
              onClick={() => {
                setStep(1);
              }}
              fullWidth={true}
              className="custom-button ghost-button"
            >
              Cancel
            </CustomButton>
            <CustomButton
              loading={isAddingFunds || loadingPaymentLink}
              disabled={false}
              onClick={openPayment}
              fullWidth={true}
              className="custom-button primary-button"
            >
              Confirm
            </CustomButton>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal show={isOpen}>
      <div className="content">
        <div className="card-modal-header">
          <div className="d-flex align-items-center cursor" onClick={payToggleHandler}>
            {step === 1 || isLoaded ? (
              <CloseOutlined />
            ) : (
              <LeftIcon className="cursor" onClick={payToggleHandler} />
            )}
            <span className="ps-1">{step === 1 || isLoaded ? 'Close' : 'Back'}</span>
          </div>
        </div>
        <div className="card-modal-body">
          {step === 1 ? (
            <div className="beneficiaries">
              <h2 className="card-title w-100">{modalHeading ?? 'Move funds'}</h2>
              <CustomInput
                label="How much do you want to move?"
                isAmount
                placeholder="0.00"
                type="text"
                name="amount"
                className="mb-2 amount"
                value={amount}
                onChange={handleChange}
                getTypeVal={(val) => setCurrency(val)}
              />

              <CustomSelect
                label="From"
                name="account"
                placeholder="Select balance"
                onChange={(account) => setBankAccount(account)}
                value={account}
                options={balanceOptions}
                wrapperClass="mb-2"
                isLoading={loadingBalances}
              />

              <CustomSelect
                label="To"
                name="destination"
                placeholder="Select balance"
                onChange={(account) => setDestination(account)}
                value={destination}
                options={balanceOptions.filter(({ value }) => value !== account?.value)}
                wrapperClass="mb-2"
                isDisabled={!!defaultSelectedAccount?.isDestinationDisabled}
              />

              <div className="modal-footer border-top">
                <CustomButton
                  onClick={payToggleHandler}
                  fullWidth={true}
                  className="custom-button ghost-button"
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  loading={false}
                  disabled={false}
                  onClick={handleNextStep}
                  fullWidth={true}
                  className="custom-button primary-button"
                >
                  Continue
                </CustomButton>
              </div>
            </div>
          ) : (
            <PreviewCard />
          )}
        </div>
      </div>
    </Modal>
  );
};
export default SwapModal;
