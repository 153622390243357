import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import BillAnalytics from '../Analytics';
import Table from 'components/Table';
import BillDetail from '../BillDetail';
import TopBar from 'components/TopBar';
import BillsEmptyState from '../EmptyState';
import SearchLoader from '../SearchLoader';
import { Col, Row, Container } from 'react-bootstrap';

import { fetchBills } from 'redux/actions/BillAction';

import { buildBillsTableData } from 'utils/helper';
import { useDebounce } from 'hooks/useDebounce';
import { billColumns } from 'utils/mockData';
import { InvoiceStatusType } from 'components/FilterModal/FilterHelper';

const AllBills = ({
  handleDelete,
  filteredQuery,
  setFilteredQuery,
  isAdmin,
  isPopoverOpen,
  setIsPopoverOpen,
  BillActions,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const [activeRow, setActiveRow] = useState({});
  const [isDrawer, setIsDrawer] = useState(false);
  const [activeTags, setActiveTags] = useState([]);
  const [showEmptyState, setShowEmptyState] = useState(false);
  const [filterData, setFilterData] = useState([...InvoiceStatusType]);

  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const debouncedValue = useDebounce(search, 700);

  const {
    fetchBills: { loading, success, data },
  } = useSelector(({ bills }) => bills);

  const { meta: { page, total, hasMore, perPage, nextPage } = {}, bills = [] } =
    data || {};

  useEffect(() => {
    if (debouncedValue) {
      filteredQuery.search = debouncedValue;
      dispatch(fetchBills({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered) {
      delete filteredQuery.search;
      dispatch(fetchBills({ ...filteredQuery }));
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const rows = buildBillsTableData(data?.bills || [], isAdmin);

  const onHandleNextPage = (page) => {
    dispatch(fetchBills({ ...filteredQuery, perPage, page }));
    setFilteredQuery({ ...filteredQuery, perPage, page });
  };

  const onHandlePreviousPage = (page) => {
    dispatch(fetchBills({ ...filteredQuery, perPage, page }));
    setFilteredQuery({ ...filteredQuery, perPage, page });
  };

  const clearFilters = () => {
    setFilteredQuery({});
    setFilterData([...InvoiceStatusType]);
    dispatch(fetchBills());
  };

  const onHandleFilter = (query) => {
    const amount = filterData.filter(({ title }) => title === 'amount');

    const [min, max] = amount[0]?.value || [];
    const minAmount = min ? min * 100 : undefined;
    const maxAmount = max ? max * 100 : undefined;
    setFilteredQuery({ ...query, minAmount, maxAmount });

    dispatch(fetchBills({ ...query, minAmount, maxAmount }));
    isFiltered.current = !!Object.keys(query).length;
  };

  const handleClick = (data) => {
    if (!loading) {
      const target = data.target.toLowerCase();
      const alreadyExists = activeTags.includes(target);

      let updatedTags;

      if (alreadyExists) {
        updatedTags = activeTags.filter((tag) => tag !== target);
      } else {
        updatedTags = [...activeTags, target];
      }

      setActiveTags(updatedTags);
      onHandleFilter({ status: updatedTags });
    }
  };

  const isSchedule = activeRow?.code?.split('_')[0] === 'sbl';

  const handleRowClick = (row) => {
    setActiveRow(row);
    setIsDrawer(true);
  };

  useEffect(() => {
    let timer;
    if (!loading && !bills.length) {
      timer = setTimeout(() => {
        setShowEmptyState(true);
      }, 0);
    } else {
      setShowEmptyState(false);
    }

    return () => clearTimeout(timer);
  }, [loading, bills]);

  return (
    <>
      <Container className="px-0">
        <BillAnalytics
          data={data}
          loading={loading}
          activeTags={activeTags}
          handleClick={handleClick}
        />

        <TopBar
          showBarSearch={!!bills.length}
          searchVal={search}
          setSearchVal={setSearch}
          showFilter={!!bills.length}
          handleFilterSelect={(updateVal) => {
            setFilterData(updateVal);
          }}
          filterData={filterData}
          handleFilterApply={onHandleFilter}
          clearFilters={clearFilters}
          inputPlaceholder="search"
          withDate
        />

        {!loading && showEmptyState && !bills.length ? (
          <BillsEmptyState />
        ) : (
          <Row className="pt-4 pb-3">
            <Col xs={12} className="spaced-table">
              <Table
                columns={billColumns}
                pagination
                data={rows}
                onRowClick={handleRowClick}
                hasCheckBox={false}
                popoverAction={BillActions}
                popoverState={isPopoverOpen}
                setPopoverState={setIsPopoverOpen}
                hasMore={hasMore}
                currentPage={page}
                nextPage={() => onHandleNextPage(nextPage)}
                previousPage={() => onHandlePreviousPage(page - 1)}
                totalPage={Math.ceil(total / perPage || 1)}
                loading={loading}
              />
            </Col>
          </Row>
        )}
      </Container>

      <BillDetail
        isOpen={isDrawer}
        code={activeRow?.code}
        isSchedule={isSchedule}
        handleClose={() => setIsDrawer(false)}
      />
    </>
  );
};

export default AllBills;
