import {
  ArrowRight,
  BankNoteO1Icon,
  CalendarPlusIcon,
  LayersTwoIcon,
} from 'assets/icons';
import TopBar from 'components/TopBar';
import CustomButton from 'components/UI/CustomButton';
import CustomPopover from 'components/UI/Popover';
import 'jspdf-autotable';
import ApprovalRules from 'pages/Approvals/ApprovalRules';
import PendingApprovals from 'pages/Approvals/PendingApprovals';
import { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { fetchPendingApprovals } from 'redux/actions/ApprovalAction';
import { toggleAction } from 'redux/actions/ToggleAction';
import { SELECTED_TABLE_ROWS } from 'redux/reducers/TableReducer';
import AllowedTo, { allPermissions, hasPermission } from 'utils/AllowedTo';
import BulkTransactionTable from './BulkTransactionTable';
import ScheduledTransactionTable from './ScheduledTransactionTable';
import TransactionTable from './TransactionTable';
import BulkAssignTransactionAside from './components/BulkAssignTransactionAside';
import { SendFundsButton } from 'components/UI/CustomButton';
import './style.less';
import { useMediaQuery } from 'react-responsive';

const Transactions = () => {
  const dispatch = useDispatch();
  const { permissions, isAdmin } = allPermissions();
  const { transactionCode, requestCode, code } = useParams();

  const location = useLocation();
  const locationArray = location.pathname.split('/');
  const history = useHistory();
  const [openBulkAssign, setOpenBulkAssign] = useState(false);
  const [key, setKey] = useState(locationArray[locationArray.length - 1]);

  const canEditTransaction = hasPermission({
    permissions,
    scopes: ['transaction-*', 'transaction-edit'],
  });

  const {
    fetchPendingApprovals: { data: pendingApproval, meta: approvalsMeta },
    fetchApprovalRules: { data: { meta: approvalMeta } = {} },
  } = useSelector(({ approval }) => approval);

  const {
    getTransaction: { data: { meta = {} } = {}, loading },
    getScheduledTransaction: { data: { meta: scheduledMeta } = {} },
  } = useSelector(({ transaction }) => transaction);

  const {
    getBatchPayment: { data: Batchtransactions = [] },
  } = useSelector(({ payments }) => payments);

  const {
    selectedTableRows: { selectedRows, type },
  } = useSelector(({ table }) => table);

  const { total } = meta;

  useEffect(() => {
    const type = ['payments', 'requests'].includes(key) ? key : null;
    dispatch({
      type: SELECTED_TABLE_ROWS,
      payload: {
        selectedRows: [],
        type: type,
      },
    });
  }, [key]);

  const handleSelect = (tabKey) => {
    setKey(tabKey);

    if (tabKey != 'payments') {
      history.push(`/transactions/${tabKey}`);
    } else {
      history.push(`/transactions/payments`, { disableFade: true });
    }
  };

  useEffect(() => {
    if (requestCode) {
      setKey('requests');
    }
    if (code) {
      setKey('rules');
    }

    if (transactionCode) {
      setKey('payments');
    }
  }, [requestCode, code, transactionCode]);

  useEffect(() => {
    if (!pendingApproval?.length) dispatch(fetchPendingApprovals());
  }, []);

  const [isButtonToggle, setIsButtonToggle] = useState(false);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);
  const isSchedule = () => {
    localStorage.setItem('schedule', 'on');
  };

  const handleRowSelect = (rowData, type) => {
    dispatch({
      type: SELECTED_TABLE_ROWS,
      payload: { selectedRows: rowData, type },
    });
  };

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
          }}
        >
          <BankNoteO1Icon /> Single payment
        </div>
        <AllowedTo
          scopes={[
            'batch-transaction-*',
            'batch-transaction-view',
            'batch-transaction-create',
          ]}
        >
          <div
            className="actionLink"
            onClick={() => {
              history.push('/transactions/batch-payment');
            }}
          >
            <LayersTwoIcon /> Batch payments
          </div>
        </AllowedTo>
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
            isSchedule();
          }}
        >
          <CalendarPlusIcon /> Schedule payment
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!isAdmin && locationArray.includes('rules')) {
      history.push('/404');
    }
  }, []);

  const showBulkAssign =
    selectedRows.length > 0 &&
    canEditTransaction &&
    openBulkAssign &&
    key === 'payments' &&
    type === 'payments';

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className={`${showBulkAssign ? 'transaction-page-grid' : ''}`}>
      <div className="position-relative container">
        <div>
          <TopBar
            headerText="Transactions"
            subText="Manage your transactions: manage requests, approval processes, bulk payments, and scheduled transactions."
            isRightBar
            dropDownBtn={
              <AllowedTo scopes={['transaction-*', 'transaction-create']}>
                <CustomPopover
                  zIndex="1"
                  showPopover={isButtonToggle}
                  clickOutside={handleButtonToggle}
                  content={<Actions />}
                  placement={isTabletOrMobile ? 'bottom' : 'bottom-end'}
                  id={isTabletOrMobile ? 'invoice-schedule' : ''}
                >
                  <SendFundsButton onClick={handleButtonToggle} />
                </CustomPopover>
              </AllowedTo>
            }
            addButton
          />
        </div>

        <section className={loading ? ' fade-in' : ''}>
          <Row className="overview-holder shallow">
            <Col xs={12} className="mt-4">
              <Tabs
                id="contolled-tab-example"
                activeKey={key}
                onSelect={handleSelect}
                className="mb-2"
              >
                {hasPermission({
                  permissions,
                  scopes: ['transaction-*', 'transaction-view', 'transaction-create'],
                }) && (
                  <Tab
                    eventKey="payments"
                    title={
                      <div className="d-flex">
                        All transactions{' '}
                        <div className="count">
                          <span className="m-auto">{total || 0}</span>
                        </div>
                      </div>
                    }
                    tabClassName="new-tab"
                  >
                    <div className="fade-in">
                      <TransactionTable
                        transactionCode={transactionCode}
                        handleSelect={setKey}
                        onRowSelect={(data) => {
                          handleRowSelect(data, 'payments');
                        }}
                        setOpenBulkAssign={setOpenBulkAssign}
                        currentLocation={locationArray[1]}
                      />
                    </div>
                  </Tab>
                )}

                <Tab
                  eventKey="requests"
                  title={
                    <div className="d-flex">
                      Needs review
                      <div className="count">
                        <span className="m-auto">{approvalsMeta?.total || 0}</span>
                      </div>
                    </div>
                  }
                  tabClassName="new-tab"
                >
                  <div className="fade-in">
                    <PendingApprovals
                      requestCode={requestCode}
                      onRowSelect={(data) => {
                        handleRowSelect(data, 'requests');
                      }}
                      handleSelect={setKey}
                    />
                  </div>
                </Tab>
                {hasPermission({
                  permissions,
                  scopes: [
                    'batch-transaction-*',
                    'batch-transaction-view',
                    'batch-transaction-create',
                  ],
                }) && (
                  <Tab
                    eventKey="batch"
                    title={
                      <div className="d-flex">
                        Batches{' '}
                        <div className="count">
                          <span className="m-auto">{Batchtransactions.length || 0}</span>
                        </div>
                      </div>
                    }
                    tabClassName="new-tab"
                  >
                    <div className="fade-in">
                      {' '}
                      <BulkTransactionTable handleSelect={setKey} />{' '}
                    </div>
                  </Tab>
                )}

                <Tab
                  eventKey="scheduled"
                  title={
                    <div className="d-flex">
                      Scheduled
                      <div className="count">
                        <span className="m-auto">{scheduledMeta?.total ?? 0}</span>
                      </div>
                    </div>
                  }
                  tabClassName="new-tab"
                >
                  <div className="fade-in">
                    <ScheduledTransactionTable handleSelect={setKey} />
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </section>
      </div>
      {showBulkAssign && (
        <BulkAssignTransactionAside setOpenBulkAssign={setOpenBulkAssign} />
      )}
    </div>
  );
};

export default Transactions;
