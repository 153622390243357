/**
 *
 * @param {function} handleClose: a callback function to close the drawer
 * @param {boolean} status: the status of the transaction
 * @returns
 */

import { KebabIcon, XcloseIcon } from 'assets/icons';
import CustomPopover from 'components/UI/Popover';
import BadgeType from 'components/UI/Table/BadgeType';
import CurrencyFormat from 'react-currency-format';
import { Link, useHistory } from 'react-router-dom';
import { getColor, getCurrency } from 'utils/helper';
import ImgCard from 'components/UI/ImgCard';

const DrawerHeader = ({
  handleClose,
  status,
  popoverData,
  receipient,
  moneyData,
  title,
  badgeIcon = null,
  recipientLink = null,
}) => {
  const history = useHistory();
  return (
    <div className="card-modal-header d-flex flex-column">
      <div className="d-flex flex-column w-full">
        <div className="cursor" onClick={handleClose}>
          <XcloseIcon stroke="#57534e" />
          <span className="ps-1 text-sm color-grey-600">Close</span>
        </div>
        <div className="w-full d-flex justify-content-between align-items-center mt-4">
          <h2 className="color-grey-900 text-ds-xs font-semibold">{title}</h2>
          {popoverData && (
            <div>
              <CustomPopover
                content={popoverData?.action}
                showPopover={popoverData?.isPopoverOpen}
                clickOutside={popoverData?.handleTogglePopover}
              >
                <div onClick={popoverData?.handleTogglePopover} className="cursor">
                  <KebabIcon stroke="#A9A29D" />
                </div>
              </CustomPopover>
            </div>
          )}
        </div>
      </div>
      <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
        <div
          className="d-flex mb-2 cursor"
          onClick={recipientLink ? () => history.push(recipientLink) : null}
        >
          <ImgCard initials={receipient && receipient[0].trim()} size="xlarge" />
        </div>

        <CurrencyFormat
          prefix={getCurrency(moneyData?.currency)}
          value={moneyData?.amount / 100}
          displayType="text"
          thousandSeparator={true}
          className="details-amount font-semibold"
        />
        {recipientLink ? (
          <Link to={recipientLink} target="_blank" className="recipient-link">
            <span className="font-medium text-base">{receipient}</span>
          </Link>
        ) : (
          <span className="font-medium text-base">{receipient}</span>
        )}
        <span className="mt-4">
          <BadgeType
            value={{ value: status, color: getColor(status), icon: badgeIcon }}
          />
        </span>
      </div>
    </div>
  );
};

export default DrawerHeader;
