import { Checkbox, Skeleton } from 'antd';
import classNames from 'classnames';
import AccordionCard from 'components/UI/AccordionCard';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomInput from 'components/UI/CustomInput';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import CustomSelect from 'components/UI/CustomSelect';
import ServerFileUpload from 'components/UI/FileUpload/ServerFileUpload';
import { toastError } from 'components/UI/toast';
import { addYears } from 'date-fns';
import dayjs from 'dayjs';
import NotificationBanner from 'pages/Onboarding/components/NotificationBanner';
import ViewFile from 'pages/Onboarding/components/ViewFile';
import { useEffect, useMemo, useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCompany,
  getCountries,
  getState,
  updateCompany,
  uploadOnboardingDocuments,
} from 'redux/actions/CompaniesAction';
import { validateObjectData } from 'utils';
import { businessTypes, removeEmptyString, validateNigeriaNumber } from 'utils/helper';

const getActiveStep = (steps) => {
  if (!steps?.substeps?.incorperationDetails) return '0';
  if (!steps?.substeps?.contactInformation) return '1';
  if (!steps?.substeps?.businessDocs) return '2';
  if (steps?.completed) return '';
  return '0';
};
const BusinessDetails = () => {
  const [activeKey, setActiveKey] = useState('0');
  const [isChecked, setIsChecked] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [stateIsSet, setStateIsSet] = useState(false);

  const dispatch = useDispatch();
  const {
    getCompany: { loading: loadingCompany, data: companyData },
    uploadOnboardingDocuments: { loading: loadingUpload, success: successUpload },
    updateCompany: { loading: updateLoading, success: updateSuccess },
    getCountry: { data: countryData = {}, loading: loadingCountry },
    getState: { data: states = {}, success: successState, loading: loadingState },
  } = useSelector(({ companies }) => companies);

  const [businessDetails, setBusinessDetails] = useState({
    incorporationCertificate: '',
    memorandum: '',
    utilityBill: '',
    internationalFormat: '',
    localFormat: '',
    countryCode: '',
    name: '',
    businessType: '',
    dateOfRegistration: '',
    email: '',
    countryAddress: '',
    stateAddress: '',
    cityAddress: '',
    streetAddress: '',
    countryRegistered: '',
    stateRegistered: '',
    cityRegistered: '',
    streetRegistered: '',
    industry: '',
    size: '',
    website: '',
    description: '',
  });

  const getDocuments = (key) => {
    return companyData?.documents.find((item) => item.value === key);
  };

  const handlePhoneNumberChange = (localFormat, internationalFormat, countryCode) => {
    setBusinessDetails({
      ...businessDetails,
      internationalFormat,
      localFormat,
      countryCode,
    });
  };
  const dynamicDocumentInput = useMemo(
    () => companyData?.documents.filter((item) => item.type === 'text'),
    [companyData?.code],
  );

  const listDocumentsFile = useMemo(() => {
    const all = companyData?.documents?.filter((item) => item.type === 'file');

    const pendings = companyData?.documents?.filter(
      (item) => item.type === 'file' && ['pending', 'rejected'].includes(item.status),
    );

    const cacDocumnet = companyData?.documents?.find((document) =>
      ['incorp_C', 'cac'].includes(document?.value),
    );

    const submittedCac = !['pending', 'rejected'].includes(cacDocumnet?.status);

    const rejected = companyData?.documents?.filter(
      (item) => item?.type === 'file' && ['rejected'].includes(item?.status),
    );

    const rejectedMessage = rejected?.map((doc) => {
      return (
        <span key={doc?.value}>
          <span className="fw-semibold">{doc?.name}</span>:{' '}
          {doc?.rejectedReason || 'No reason provided'}
        </span>
      );
    });

    const rejectedMessageElement = (
      <>
        Please re-upload the following documents, as the ones submitted were rejected:{' '}
        {rejectedMessage}
      </>
    );

    return {
      all,
      pendings,
      rejected,
      rejectedMessage: rejectedMessageElement,
      submittedCac,
    };
  }, [companyData?.documents]);

  const generateCountry = useMemo(() => {
    if (countryData?.length > 0) {
      return countryData
        ?.filter((item) => item?.name === 'NIGERIA')
        ?.map(({ code: value, name: label }) => ({
          value,
          label,
          isDisabled: label !== 'NIGERIA' ? true : false,
        }));
    }
  }, [countryData]);

  const generateState = useMemo(() => {
    if (successState) setStateIsSet(true);
    return states?.states?.map((value) => ({
      label: value,
      value: value,
    }));
  }, [successState]);

  useEffect(() => {
    if (!countryData?.length) dispatch(getCountries());
  }, []);

  const getCountryStates = async (val) => {
    dispatch(getState(val.value));
  };

  useEffect(() => {
    if (companyData?.code && generateCountry?.length) {
      if (companyData?.address?.country) {
        const country = generateCountry?.find((item) =>
          [item?.label, item.value].includes(companyData?.address?.country),
        );
        dispatch(getState(country?.value));
      }
    }
  }, [generateCountry?.length, companyData?.code]);

  useEffect(() => {
    if (companyData?.code && !loadingCompany)
      setActiveKey(getActiveStep(companyData?.onboardingSteps?.businessDetails));
    if (companyData?.code && stateIsSet && !loadingCompany) {
      setBusinessDetails({
        ...businessDetails,
        ...companyData,
        businessType: companyData?.businessType
          ? {
              value: companyData?.businessType,
              label: businessTypes.find(
                (item) => item?.value === companyData?.businessType,
              ).label,
            }
          : '',

        rcNumber: getDocuments('rcNumber')?.number || '',
        cacITNumber: getDocuments('cacITNumber')?.number || '',
        bnNumber: getDocuments('bnNumber')?.number || '',
        dateOfRegistration: companyData?.dateOfRegistration || '',
        tin: getDocuments('tin')?.number || '',
        email: companyData?.contactEmail || '',
        countryCode: companyData?.phoneNumber?.countryCode || '',
        localFormat: companyData?.phoneNumber?.localFormat || '',
        internationalFormat: companyData?.phoneNumber?.internationalFormat
          ? `+${companyData?.phoneNumber?.internationalFormat}`
          : '',
        cityAddress: companyData?.address?.city || '',
        streetAddress: companyData?.address?.street || '',
        postCodeAddress: companyData?.address?.postalCode || '',
        countryAddress:
          generateCountry?.find((item) =>
            [item?.label, item.value].includes(companyData?.address?.country),
          ) || '',
        stateAddress:
          generateState?.find((item) => item.label === companyData?.address?.state) || '',

        cityRegistered: companyData?.registeredAddress?.city || '',
        streetRegistered: companyData?.registeredAddress?.street || '',
        postCodeRegistered: companyData?.registeredAddress?.postalCode || '',

        countryRegistered:
          generateCountry?.find((item) =>
            [item?.label, item.value].includes(companyData?.address?.country),
          ) || '',
        stateRegistered:
          generateState?.find(
            (item) => item.label === companyData?.registeredAddress?.state,
          ) || '',
      });

      setIsChecked(!!companyData?.registeredAddress?.country);
    }
  }, [companyData?.code, stateIsSet, loadingCompany]);

  useEffect(() => {
    if (updateSuccess || successUpload) {
      dispatch(
        getCompany({
          code: companyData?.code,
          includeDocuments: true,
          includeSteps: true,
        }),
      );

      if (successUpload) {
        const docmentsToSubmit = listDocumentsFile?.all?.reduce((acc, item) => {
          acc[item.value] = undefined; // Map key to value
          return acc;
        }, {});
        setBusinessDetails({ ...businessDetails, ...docmentsToSubmit });
      }
    }
  }, [updateSuccess, successUpload]);

  const handleInputChange = ({ target: { name, value } }) => {
    setBusinessDetails({ ...businessDetails, [name]: value });
  };

  const handleChangeDate = (value) => {
    setBusinessDetails({
      ...businessDetails,
      dateOfRegistration: dayjs(value).format('YYYY-MM-DD'),
    });
  };

  const handleSubmit = () => {
    if (activeKey === '0') {
      if (!businessDetails?.name) return toastError('Please enter a legal business name');
      if (!businessDetails?.businessType) return toastError('Please select registration');
      // if (!businessDetails.rcNumber)
      //   return toastError('Please enter registration number');
      if (!businessDetails?.dateOfRegistration)
        return toastError('Please enter company registration date');

      const payloadData = {
        toastMessage: 'Incorporation details updated',
        code: companyData?.code,
        // name: businessDetails.name,
        businessType: businessDetails?.businessType?.value,
        dateOfRegistration: businessDetails?.dateOfRegistration,
        // showToast: true,
      };
      if (businessDetails?.tin) {
        dispatch(
          uploadOnboardingDocuments({
            code: companyData?.code,
            tin: businessDetails?.tin,
          }),
        );
      }
      dispatch(updateCompany(payloadData));
    }
    if (activeKey === '1') {
      if (!businessDetails?.email) return toastError('Please enter business email');

      if (
        !isValidPhoneNumber(businessDetails?.internationalFormat) ||
        !validateNigeriaNumber(businessDetails?.internationalFormat)
      )
        return toastError('Please enter a valid phone number');

      if (!businessDetails?.streetAddress) return toastError('Please enter address');
      if (!businessDetails?.cityAddress) return toastError('Please enter city');
      if (!businessDetails?.countryAddress) return toastError('Please select country');
      if (!businessDetails?.stateAddress) return toastError('Please select state');
      // if (!businessDetails?.postcodeAddress) return toastError('Please enter post code');
      if (isChecked) {
        if (!businessDetails?.streetRegistered)
          return toastError('Please enter business address');
        if (!businessDetails?.cityRegistered)
          return toastError('Please enter business city');
        if (!businessDetails?.countryRegistered)
          return toastError('Please select business country');
        if (!businessDetails?.stateRegistered)
          return toastError('Please select business state');
        // if (!businessDetails?.postCodeRegistered)
        //   return toastError('Please enter business post code');
      }

      const payloadData = {
        toastMessage: 'Contact information updated',
        code: companyData?.code,
        email: businessDetails?.email,
        phoneNumber: {
          countryCode: businessDetails?.countryCode,
          localFormat: businessDetails?.localFormat,
        },
        address: {
          country: businessDetails?.countryAddress?.value,
          state: businessDetails?.stateAddress?.value,
          city: businessDetails?.cityAddress,
          street: businessDetails?.streetAddress,
          postalCode: businessDetails?.postCodeAddress
            ? businessDetails?.postCodeAddress
            : undefined,
        },
        registeredAddress: isChecked
          ? {
              country: businessDetails?.countryRegistered?.value,
              state: businessDetails?.stateRegistered?.value,
              city: businessDetails?.cityRegistered,
              street: businessDetails?.streetRegistered,
              postalCode: businessDetails?.postCodeRegistered
                ? businessDetails?.postCodeRegistered
                : undefined,
            }
          : {
              country: businessDetails?.countryAddress?.value,
              state: businessDetails?.stateAddress?.value,
              city: businessDetails?.cityAddress,
              street: businessDetails?.streetAddress,
              postalCode: businessDetails?.postCodeAddress
                ? businessDetails?.postCodeAddress
                : undefined,
            },
      };
      dispatch(updateCompany(payloadData));
    }

    if (activeKey === '2') {
      const docmentsToSubmit = listDocumentsFile?.all?.reduce((acc, item) => {
        acc[item?.value] = businessDetails[item?.value]; // Map key to value
        return acc;
      }, {});

      const { isValid, message } = validateObjectData(docmentsToSubmit);
      // if (!isValid) return toastError(message);

      const payloadData = {
        toastMessage: 'Business documents updated',
        code: companyData?.code,
        ...removeEmptyString(docmentsToSubmit),
      };
      dispatch(uploadOnboardingDocuments(payloadData));
    }
  };

  const handleSelect = (key) => {
    if (activeKey === key) return setActiveKey('999999999');
    setActiveKey(key);
  };

  const handleChangeCheckBox = (checked) => {
    setIsChecked(checked);
  };

  const handleCancel = () => {
    setActiveKey('');
  };

  return (
    <Row className="w-100 p-0 m-0">
      <Col className="p-0 m-0">
        <Accordion defaultActiveKey="0" className="p-0 m-0" activeKey={activeKey}>
          <AccordionCard
            eventKey="0"
            activeKey={activeKey}
            title="Incorporation details"
            handleClick={handleSelect}
            wrapperClass="p-0 m-0 border-bottom pb-3 bg-transparent"
          >
            <Col className="border py-2 rounded-3 mt-3">
              {loadingCompany ? (
                <LoadingSkeleton />
              ) : (
                <>
                  {' '}
                  <div className="px-3">
                    <Row className="mb-2">
                      <CustomInput
                        type="text"
                        label="Legal business name"
                        placeholder="Enter legal business name"
                        name="name"
                        disabled={companyData?.name}
                        value={businessDetails.name}
                        onChange={handleInputChange}
                      />
                    </Row>

                    <Row className="mb-2">
                      <CustomSelect
                        label="Registration type"
                        name="businessType"
                        placeholder="Select registration type"
                        onChange={(val) =>
                          setBusinessDetails({ ...businessDetails, businessType: val })
                        }
                        value={businessDetails.businessType}
                        options={businessTypes}
                      />
                    </Row>

                    <Row className="align-items-center gap-md-0 gap-2 ">
                      {dynamicDocumentInput?.map((item, index) => (
                        <CustomInput
                          key={index}
                          type="text"
                          label={`${item?.name} *`}
                          placeholder={`Enter ${item?.name}`}
                          name={item?.value}
                          onChange={handleInputChange}
                          value={businessDetails?.[item?.value]}
                          md={6}
                          disabled={item?.value === 'rcNumber'}
                          className="mb-3"
                        />
                      ))}

                      <CustomDatePicker
                        wrapperClass="mb-3"
                        label="Registration date"
                        name="dateOfRegistration"
                        onChange={handleChangeDate}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="Registration date"
                        maxDate={addYears(new Date(), 0)}
                        selected={
                          businessDetails?.dateOfRegistration &&
                          dayjs(businessDetails?.dateOfRegistration).toDate()
                        }
                        md={6}
                      />
                    </Row>
                  </div>
                  <div className="border-top button-container w-100 px-3 pt-3 me-md-auto pb-2 d-md-flex align-items-center gap-3">
                    <button
                      onClick={handleCancel}
                      type="button"
                      className="btn border sm ms-md-auto mb-md-0 mb-2"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      disabled={updateLoading}
                      onClick={handleSubmit}
                      className="btn black sm"
                    >
                      Save and submit
                    </button>
                  </div>
                </>
              )}
            </Col>
          </AccordionCard>

          {/******************** Contact information  ***************/}
          <AccordionCard
            eventKey="1"
            activeKey={activeKey}
            title="Contact information"
            handleClick={handleSelect}
            wrapperClass={classNames('m-0 border-bottom bg-transparent', {
              // ['disabled']: !substeps?.incorperationDetails,
            })}
          >
            <Col className="border py-2 rounded-3 mt-3">
              <div className="px-3">
                <Row className="mb-2 align-items-center">
                  <CustomInput
                    type="text"
                    label="Company email"
                    placeholder="Enter company email"
                    name="email"
                    onChange={handleInputChange}
                    value={businessDetails.email}
                    md={6}
                  />

                  <CustomPhoneNumberInput
                    label="Phone number"
                    placeholder="Enter your mobile number"
                    md={6}
                    onChange={(localFormat, international, countryCode) =>
                      handlePhoneNumberChange(localFormat, international, countryCode)
                    }
                    value={businessDetails.internationalFormat}
                  />
                </Row>
                <Row className="mb-2">
                  <CustomInput
                    type="text"
                    label="Operating business address"
                    placeholder="Enter operating address"
                    name="streetAddress"
                    onChange={handleInputChange}
                    value={businessDetails.streetAddress}
                  />
                </Row>
                <Row className="mb-2">
                  <CustomInput
                    type="text"
                    label="City"
                    placeholder="Enter city"
                    name="cityAddress"
                    onChange={handleInputChange}
                    value={businessDetails.cityAddress}
                  />
                </Row>
                <Row className="mb-2 align-items-center">
                  <CustomSelect
                    md={6}
                    label="State / Province"
                    placeholder="Select state"
                    name="stateAddress"
                    isLoading={loadingState}
                    onChange={(val) =>
                      setBusinessDetails({
                        ...businessDetails,
                        stateAddress: val,
                      })
                    }
                    value={businessDetails.stateAddress}
                    options={generateState}
                  />

                  <CustomInput
                    label="Postcode"
                    placeholder="Enter your post code"
                    name="postCodeAddress"
                    onChange={handleInputChange}
                    value={businessDetails.postCodeAddress}
                    md={6}
                  />
                </Row>
                <Row className="mb-3">
                  <CustomSelect
                    label="Country"
                    name="countryAddress"
                    placeholder="Select country"
                    isLoading={loadingCountry}
                    onChange={(val) => {
                      setBusinessDetails({
                        ...businessDetails,
                        countryAddress: val,
                      });
                      getCountryStates(val);
                    }}
                    value={businessDetails.countryAddress}
                    options={generateCountry}
                    isCountry
                  />
                </Row>
                <Row className="my-3">
                  <Checkbox
                    checked={isChecked}
                    onChange={({ target: { checked } }) => handleChangeCheckBox(checked)}
                  >
                    Registered address differs from operating address
                  </Checkbox>
                </Row>
                {isChecked && (
                  <>
                    <Row className="mb-2">
                      <CustomInput
                        type="text"
                        label="Registred business address"
                        placeholder="Enter business address"
                        name="streetRegistered"
                        onChange={handleInputChange}
                        value={businessDetails.streetRegistered}
                      />
                    </Row>
                    <Row className="mb-2">
                      <CustomInput
                        type="text"
                        label="City"
                        placeholder="Enter city"
                        name="cityRegistered"
                        onChange={handleInputChange}
                        value={businessDetails.cityRegistered}
                      />
                    </Row>
                    <Row className="mb-2 align-items-center">
                      <CustomSelect
                        md={6}
                        label="State / Province"
                        placeholder="Select state"
                        name="stateRegistered"
                        isLoading={loadingState}
                        onChange={(val) =>
                          setBusinessDetails({
                            ...businessDetails,
                            stateRegistered: val,
                          })
                        }
                        value={businessDetails.stateRegistered}
                        options={generateState}
                      />

                      <CustomInput
                        label="Postcode"
                        placeholder="Enter your post code"
                        name="postCodeRegistered"
                        onChange={handleInputChange}
                        value={businessDetails?.postCodeRegistered}
                        md={6}
                      />
                    </Row>
                    <Row className="mb-3">
                      <CustomSelect
                        label="Country"
                        name="countryRegistered"
                        placeholder="Select country"
                        isLoading={loadingCountry}
                        onChange={(val) => {
                          setBusinessDetails({
                            ...businessDetails,
                            countryRegistered: val,
                          });
                          getCountryStates(val);
                        }}
                        value={businessDetails.countryRegistered}
                        options={generateCountry}
                        isCountry
                      />
                    </Row>
                  </>
                )}
              </div>
              <div className="border-top button-container w-100 px-3 pt-3 me-md-auto pb-2 d-md-flex align-items-center gap-3">
                <button
                  onClick={handleCancel}
                  type="button"
                  className="btn border sm ms-md-auto mb-md-0 mb-2"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  disabled={updateLoading}
                  onClick={handleSubmit}
                  className="btn black sm"
                >
                  Save and submit
                </button>
              </div>
            </Col>
          </AccordionCard>

          {/******************** Business documents  ***************/}
          <AccordionCard
            eventKey="2"
            activeKey={activeKey}
            title="Business documents"
            handleClick={handleSelect}
            wrapperClass={classNames({
              // ['disabled']: !substeps?.contactInformation,
            })}
          >
            {!!listDocumentsFile?.pendings?.length && (
              <div className="py-2 mt-3">
                <NotificationBanner
                  isError={listDocumentsFile?.rejected?.length}
                  message={
                    listDocumentsFile?.submittedCac &&
                    !listDocumentsFile?.rejected?.length
                      ? null
                      : listDocumentsFile?.rejectedMessage
                  }
                />
              </div>
            )}
            <Col className="border py-2 rounded-3 mt-3">
              <div className="px-3 mb-3">
                {listDocumentsFile?.all?.map((item, index) => (
                  <Row className="mb-2" key={index}>
                    {['pending', 'rejected'].includes(item?.status) ? (
                      <ServerFileUpload
                        label={`${item.name} *`}
                        supportType="JPEG, PNG, and PDF formats, up to 5 MB."
                        uploadedFile={businessDetails?.[item.value]}
                        onChange={({ assetCode } = {}) =>
                          setBusinessDetails({
                            ...businessDetails,
                            [item.value]: assetCode,
                          })
                        }
                        name={`file-${index}`}
                        setUploadingFile={setUploadingFile}
                        uploadingFile={uploadingFile}
                      />
                    ) : (
                      <div className="px-2">
                        <ViewFile
                          label={`${item.name} *`}
                          fileName={item?.asset?.name}
                          fileUrl={item?.asset?.url}
                          status={item.status}
                        />
                      </div>
                    )}
                  </Row>
                ))}
              </div>
              {!!listDocumentsFile?.pendings?.length && (
                <div className="border-top button-container w-100 px-3 pt-3 me-md-auto pb-2 d-md-flex align-items-center gap-3">
                  <button
                    onClick={handleCancel}
                    type="button"
                    className="btn border sm ms-md-auto mb-md-0 mb-2"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    disabled={loadingUpload}
                    onClick={handleSubmit}
                    className="btn black sm"
                  >
                    Save and submit
                  </button>
                </div>
              )}
            </Col>
          </AccordionCard>
        </Accordion>
      </Col>
    </Row>
  );
};

export default BusinessDetails;

const LoadingSkeleton = () => {
  return (
    <div className="px-3">
      <div className="pt-2 bg-transparent pb-3">
        <Row className="mb-3 mt-3 grid grid-col-2 gap-2 align-items-center">
          <Skeleton.Input active className="rounded-2 w-100" size={42} />
        </Row>

        <Row className="mb-3">
          <Skeleton.Input active className="rounded-2 w-100" size={42} />
        </Row>
        <Row className="mb-3">
          <Skeleton.Input active className="rounded-2 w-100" size={42} />
        </Row>
      </div>

      <div className="border-top bg-transparent py-3">
        <Row className="mb-2">
          <Skeleton.Input active className="rounded-2 w-100" size={52} />
        </Row>
      </div>
    </div>
  );
};
