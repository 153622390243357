import { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Skeleton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { DeleteIcon, EditIcon } from 'assets/icons';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import ApprovalRuleEmptyStateData from 'pages/Transactions/approvalRuleEmptyStateData';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import {
  deleteApprovalLevel,
  deleteApprovers,
  fetchApprovalRuleActivity,
  fetchApprovalRules,
} from 'redux/actions/ApprovalAction';
import { buildApprovalRulesTableData } from 'utils/helper';
import { approvalColumns } from 'utils/mockData';
import DeleteDialog from '../../components/DeleteDialog/index';
import { useDebounce } from 'hooks/useDebounce';

const ApprovalRules = ({
  ruleCode,
  handleSelect,
  overrideOpen = false,
  filterBy = null,
}) => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [isOpen, setIsOpen] = useState(overrideOpen);
  const [deleteOpen, setDeleteOpen] = useState(null);
  const [approvalLevelDelete, setDeleteApprovalLevel] = useState(false);
  const [approvalLevelPayload, setApprovalLevelPayload] = useState(false);
  // const [selectedRuleCode, setSelectedRuleCode] = useState(false);
  const [selectedRule, setSelectedRule] = useState({});
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const location = useLocation();
  const history = useHistory();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 600);
  const [filteredQuery, setFilteredQuery] = useState({});

  const {
    user: { data: userData },
  } = useSelector(({ auth }) => auth);

  const {
    fetchApprovalRules: { data: { rules: data = [], meta = {} } = {}, loading, success },
    deleteApprovers: { loading: isDeleting, success: isDeleted, error: isDeletingFailed },
    deleteApprovalLevel: {
      loading: isDeletingLevel,
      success: approvalLevelDeleted,
      error: isDeleteFailed,
    },
  } = useSelector(({ approval }) => approval);

  const { page, total, hasMore, perPage, nextPage } = meta;

  useEffect(() => {
    if (location.state?.isModal) {
      setIsOpen(true);
      history.replace({ state: {} });
    }
  }, [location]);

  useEffect(() => {
    setIsOpen(overrideOpen);
    history.replace({ state: {} });
  }, [overrideOpen]);

  useEffect(() => {
    if (!data.length) {
      dispatch(fetchApprovalRules({ trigger: filterBy }));
    }
  }, []);

  const onShowDeleteModal = (value) => {
    if (value.status.value === 'deleted') return;
    setSelectedRule({ name: value.name, approvalData: value?.approvalData });
    setDeleteOpen(true);
  };

  const onHandleDeleteApproval = () => {
    setDeleteOpen(true);
    dispatch(deleteApprovers(selectedRule?.approvalData.code));
  };

  const onHandleEditModal = (value) => {
    if (value.status.value === 'deleted') return;
    dispatch(
      fetchApprovalRuleActivity({
        selectedRuleCode: value.approvalData.code,
        selectedRule: value,
      }),
    );
    if (location.pathname === '/compliances/rules') {
      history.push(`/compliances/rules/edit/${value.approvalData.code}`);
    } else if (location.pathname === '/compliances/rules') {
      history.push(`/compliances/rules/edit/${value.approvalData.code}`);
    }
  };

  const toggleHandler = () => {
    if (location.pathname === '/compliances/rules') {
      history.push('/compliances/rules/create');
    } else if (location.pathname === '/compliances/rules') {
      history.push('/compliances/rules/create');
    }
  };

  const rows = buildApprovalRulesTableData(data);
  useEffect(() => {
    if (ruleCode) {
      const element = rows.find(({ code }) => code === ruleCode);
      dispatch(
        fetchApprovalRuleActivity({ selectedRuleCode: ruleCode, selectedRule: element }),
      );
      // setIsOpen(true);
    }
  }, [ruleCode]);

  const onHandleClearSelectedRule = () => {
    dispatch(fetchApprovalRuleActivity({ selectedRule: null }));
  };

  const onHandleApprovalLevelPayload = (payload) => {
    setApprovalLevelPayload(payload);
    setDeleteApprovalLevel(true);
    setIsOpen(false);
  };

  const onHandleDeleteApprovalLevel = () => {
    dispatch(deleteApprovalLevel(approvalLevelPayload));
  };

  useEffect(() => {
    if (debouncedValue) {
      filteredQuery.search = debouncedValue;
      dispatch(
        fetchApprovalRules({
          trigger: filterBy,
          ...filteredQuery,
          search: debouncedValue,
        }),
      );
    }
    if (!debouncedValue && filtered) {
      delete filteredQuery.search;
      dispatch(fetchApprovalRules({ trigger: filterBy, ...filteredQuery }));
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  useEffect(() => {
    if (approvalLevelDeleted) {
      setDeleteApprovalLevel(false);
      setTimeout(() => {
        setIsOpen(true);
      }, 200);
    }
  }, [approvalLevelDeleted]);

  useEffect(() => {
    if (isDeleteFailed) {
      setDeleteApprovalLevel(false);
      setTimeout(() => {
        setIsOpen(true);
      }, 200);
    }
  }, [isDeleteFailed]);

  const handlePreviousPage = (page) => {
    dispatch(fetchApprovalRules({ trigger: filterBy, perPage, page }));
  };

  const handleNextPage = (page) => {
    dispatch(fetchApprovalRules({ trigger: filterBy, perPage, page }));
  };

  useEffect(() => {
    if (!isDeleting && isDeleted) {
      setDeleteOpen(null);
    }
  }, [isDeleting, isDeleted]);

  useEffect(() => {
    if (!isDeleting && isDeleteFailed) {
      setDeleteOpen(null);
    }
  }, [isDeleting, isDeleteFailed]);

  const actionHandler = (event, type, data) => {
    event?.stopPropagation();
    event?.preventDefault();
    if (type === 'edit') onHandleEditModal(data);
    if (type === 'delete') onShowDeleteModal(data);
    setIsPopoverOpen(true);
  };

  const Actions = ({ list: selectedData }) => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={(event) => actionHandler(event, 'edit', selectedData)}
        >
          <EditIcon stroke="#79716B" width={16} height={16} className="mr-4" /> Edit rule
        </div>

        <div
          className="actionLink svg-danger text-danger"
          onClick={(event) => actionHandler(event, 'delete', selectedData)}
        >
          <DeleteIcon className="mr-4" width={15} height={16} /> Delete rule
        </div>
      </div>
    );
  };

  const show = !!data?.length || (filtered && !data?.length);

  const Loader = () => (
    <section className="pt-3 fade-in">
      <div className="d-flex">
        <Skeleton.Button
          active
          shape="square"
          style={{ borderRadius: 8, height: '40px', width: '16rem' }}
        />

        <div className="ms-auto d-flex gap-2">
          <Skeleton.Button
            active
            shape="square"
            style={{ borderRadius: 8, height: '40px', width: '7rem' }}
          />
        </div>
      </div>

      <div className="mt-4">
        <Table columns={approvalColumns} data={rows} hasCheckBox={false} loading />
      </div>
    </section>
  );

  if (loading && !filtered) return <Loader />;

  return (
    <>
      <TopBar
        showBarSearch={show}
        addIcon={show}
        searchVal={search}
        setSearchVal={setSearch}
        customAddButton={
          <button onClick={toggleHandler} className="add-button add-action">
            <PlusOutlined
              style={{
                verticalAlign: 0,
                fontSize: 14,
                paddingLeft: 5,
              }}
            />
            <span className="ms-1">Create new rule</span>
          </button>
        }
        addOnClick={toggleHandler}
        // showFilter
        withOutSearch
        // withDate
        inputPlaceholder="Search rule"
      />
      {!data.length ? (
        <div>
          {isFiltered.current ? (
            <div className="tabinnerWrapper">
              <NoData
                headerText={`You have no approval rule for this filter`}
                bodyText="Alter filter to see approval rules"
                withButton={false}
              />
            </div>
          ) : (
            <ApprovalRuleEmptyStateData
              handleSelect={handleSelect}
              toggleHandler={toggleHandler}
            />
          )}
        </div>
      ) : (
        <>
          <Container className="px-0 approval-table">
            <Row className="py-4">
              <Col xs={12} className="spaced-table">
                <Table
                  columns={approvalColumns}
                  data={rows}
                  pagination
                  onRowClick={onHandleEditModal}
                  hasMore={hasMore}
                  hasCheckBox={false}
                  currentPage={page}
                  nextPage={() => handleNextPage(nextPage)}
                  previousPage={() => handlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                  popoverAction={Actions}
                  popoverState={isPopoverOpen}
                  setPopoverState={setIsPopoverOpen}
                />
              </Col>
            </Row>
          </Container>
        </>
      )}
      {deleteOpen && (
        <DeleteDialog
          title={`Delete ${selectedRule.name} Rule`}
          subTitle="Are you sure you want to delete this rule? This action cannot be undone."
          onCancel={() => setDeleteOpen(false)}
          onDelete={onHandleDeleteApproval}
          styles={{ width: 400 }}
          isLoading={isDeleting}
        />
      )}
    </>
  );
};

export default ApprovalRules;
