import {
  BellIcon2,
  BudgetIcon,
  CategoryIcon,
  chartLine,
  creditCardIcon,
  DataFlowIcon,
  fileAttachmentIcon,
  fileDownloadIcon,
  FundRequestIcon,
  GiftIcon2,
  HomeLine,
  LifeBuoyIcon,
  PiggyBankIcon,
  PolicyIcon,
  ReimbursementIcon,
  SettingsIcon2,
  TransactionsIcon,
  userIcon3,
  VendorIcon,
  walletIcon2,
  Lock,
  RocketIcon,
  BankIcon02,
  NewBadgeIcon,
} from 'assets/icons';

export const NavLinks = (completedOnbording, handleClick) => {
  return [
    {
      name: 'Get started',
      description: 'Getting started',
      link: '/get-started',
      tag: 'get-started',
      icon: RocketIcon,
      permissions: completedOnbording ? [] : null,
    },
    {
      name: 'Dashboard',
      link: '/',
      tag: 'dashboard',
      icon: HomeLine,
      permissions: ['dash-view', 'dash-*'],
      subLink: [
        {
          name: 'Overview',
          tag: 'overview',
          link: '/',
        },
        {
          name: 'Reports',
          link: '/reports',
          tag: 'reports',
          permissions: ['dash-view', 'dash-*'],
        },
      ],
    },

    {
      name: 'Requests',
      link: '/requests/payments',
      action: handleClick,
      tag: 'requests',
      icon: fileDownloadIcon,
      permissions: [
        'reimbursement-*',
        'reimbursement-view',
        'reimbursement-create',
        'reimbursement-edit',
      ],
    },

    {
      name: 'Transactions',
      action: handleClick,
      tag: 'treasury',
      icon: TransactionsIcon,
      link: '/transactions/payments',
      permissions: [
        'transaction-*',
        'transaction-view',
        'transaction-edit',
        'transaction-create',
        'batch-transaction-*',
        'batch-transaction-view',
        'batch-transaction-edit',
        'batch-transaction-create',
      ],
    },
    {
      name: 'Accounts',
      tag: 'treasury',
      link: '/accounts',
      icon: BankIcon02,
      permissions: [
        'transaction-*',
        'transaction-view',
        'transaction-create',
        'transaction-edit',
        'batch-transaction-*',
        'batch-transaction-view',
        'batch-transaction-create',
        'batch-transaction-edit',
        'dash-view',
        'dash-*',
      ],
    },

    {
      name: 'Expenses',
      action: handleClick,
      link: '/expenses',
      icon: walletIcon2,
      permissions: [
        'budget-*',
        'budget-view',
        'budget-create',
        'budget-edit',
        'policy-*',
        'policy-view',
        'policy-create',
        'policy-edit',
        'vendor-*',
        'vendor-view',
        'vendor-create',
        'vendor-edit',
      ],
      subLink: [
        {
          name: 'Budgets',
          tag: 'expenses',
          link: '/expenses/budgets',
          permissions: ['budget-*', 'budget-view', 'budget-create', 'budget-edit'],
        },
        {
          name: 'Vendors',
          tag: 'expenses',
          link: '/expenses/vendors',
          permissions: ['vendor-*', 'vendor-view', 'vendor-create', 'vendor-edit'],
        },
        {
          name: 'Bills',
          tag: 'expenses',
          link: '/expenses/bills',
          badge: <span className="new-tag ms-auto text-xs">Beta</span>,
        },
        ,
      ],
    },

    {
      name: 'Cards',
      link: '/cards',
      action: handleClick,
      icon: creditCardIcon,
      permissions: ['card-*', 'card-view', 'card-create', 'card-edit'],
    },

    {
      name: 'Invoicing',
      link: '/receivables/invoices',
      links: [
        { permission: 'invoice', link: '/receivables/invoices' },
        { permission: 'invoice', link: '/receivables/requests' },
        { permission: 'customer', link: '/receivables/customers' },
        { permission: 'invoice', link: '/receivables/recurring-invoices' },
      ],
      action: handleClick,
      icon: fileAttachmentIcon,
      permissions: [
        'invoice-*',
        'invoice-view',
        'invoice-create',
        'invoice-edit',
        'customer-*',
        'customer-view',
        'customer-create',
        'customer-edit',
      ],
      subLink: [
        {
          name: 'Invoices',
          action: handleClick,
          tag: 'rules',
          link: '/receivables/invoices',
          permissions: ['org-*', 'org-view'],
        },
        {
          name: 'Customers',
          action: handleClick,
          tag: 'rules',
          link: '/receivables/customers',
          permissions: ['org-*', 'org-view'],
        },
      ],
    },
    {
      name: 'Compliance',
      action: handleClick,
      tag: 'rules',
      icon: Lock,
      link: '/compliances/rules',
      permissions: ['org-*', 'org-view'],
      subLink: [
        {
          name: 'Approval rules',
          action: handleClick,
          tag: 'rules',
          link: '/compliances/rules',
          permissions: ['org-*', 'org-view'],
        },
        {
          name: 'Expense policies',
          tag: 'expenses',
          link: '/compliances/policies',
          permissions: ['policy-*', 'policy-view', 'policy-edit', 'policy-create'],
        },
        {
          name: 'Categories',
          tag: 'expenses',
          link: '/compliances/categories',
          permissions: ['policy-*', 'policy-view', 'policy-edit', 'policy-create'],
        },
      ],
    },

    {
      name: 'Team management',
      link: '/teams',
      links: [
        { permission: 'employee', link: '/teams/people' },
        { permission: 'team', link: '/teams' },
        { permission: 'role', link: '/teams/roles' },
      ],
      icon: userIcon3,
      permissions: [
        'employee-*',
        'employee-view',
        'employee-create',
        'employee-edit',
        'team-*',
        'team-view',
        'team-create',
        'team-edit',
        'role-*',
        'role-view',
        'role-create',
        'role-edit',
      ],
    },
  ];
};
export const extraLinks = (links) => {
  return [
    /* {
      name: 'Notifications',
      link: '/notifications',
      icon: BellIcon2,
      action: links.notification,
    }, */
    {
      name: 'Settings',
      link: '/settings/profile',
      icon: SettingsIcon2,
    },
    {
      name: 'Refer & earn',
      link: '/referrals',
      icon: GiftIcon2,
    },
    {
      name: 'Help & support',
      link: '/support',
      action: links.support,
      icon: LifeBuoyIcon,
    },
  ];
};
