import { ArrowLeftOutlined, MoreOutlined } from '@ant-design/icons';
import MonoConnect from '@mono.co/connect.js';
import {
  BankIcon,
  BankNoteO1Icon,
  CalendarPlusIcon,
  ChevronRight,
  LayersTwoIcon,
  PlusDropDownIcon,
  RetryIcon,
} from 'assets/icons';
import { ReactComponent as Caution } from 'assets/icons/caution.svg';
import classNames from 'classnames';
import ConfirmDialog from 'components/ConfirmDialog';
import DeleteDialog from 'components/DeleteDialog';
import TopBar from 'components/TopBar';
import { ActiveButton, SendFundsButton } from 'components/UI/CustomButton';
import CustomPopover from 'components/UI/Popover';
import BadgeType from 'components/UI/Table/BadgeType';
import { toastSuccess } from 'components/UI/toast';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getBalances, getSingleBalance } from 'redux/actions/BudgetsAction';
import { cancelMandate } from 'redux/actions/CompaniesAction';
import { syncAccountingEntity } from 'redux/actions/IntegrationsActions';
import { openMonoConnectAction } from 'redux/actions/MonoAccountLinkingAction';
import { toggleAction } from 'redux/actions/ToggleAction';
import AllowedTo, { allPermissions, hasPermission } from 'utils/AllowedTo';
import { FormattedCurrency, getCurrency } from 'utils/helper';
import { ICONS } from './AccountCard';
import { useMediaQuery } from 'react-responsive';

const SubHeader = ({
  account,
  openModal,
  title,
  addFunds,
  setMonoCode,
  openDetailsModal,
  toggleNewAccountModal,
  triggerDataSync,
  disableActions = false,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isAdmin, permissions } = allPermissions();
  const [isButtonToggle, setIsButtonToggle] = useState(false);
  const [isFundingToggle, setIsFundingToggle] = useState(false);
  const [openMonoConnectAlert, setOpenMonoConnectAlert] = useState(false);
  const [reauthCode, setReauthCode] = useState(null);
  const [unlinking, setUnlinking] = useState({
    directDebit: false,
    linkedAccount: false,
  });

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { open: openMonoConnect, reauthenticationCode } = useSelector(
    ({ monoAccountLinking }) => monoAccountLinking,
  );

  const {
    cancelMandate: { success, loading },
  } = useSelector(({ companies }) => companies);

  const canSync = account.isLinked;

  const monoConnect = React.useMemo(() => {
    const monoInstance = new MonoConnect({
      onClose: () => {
        setOpenMonoConnectAlert(false);
        dispatch(openMonoConnectAction());
      },
      onSuccess: ({ code }) => {
        setMonoCode(code);
        monoInstance.close();
        toastSuccess('Account linking in progress...');
      },
      ...(reauthenticationCode && { data: { customer: reauthenticationCode } }),
      key: process.env.REACT_APP_MONO_CONNECT_KEY,
    });
    monoInstance.setup();

    if (reauthCode) {
      monoInstance.reauthorise(reauthCode);
    } else {
      monoInstance.setup();
    }
    return monoInstance;
  }, [reauthCode]);

  useEffect(() => {
    if (openMonoConnect) {
      setOpenMonoConnectAlert(true);
    }
    if (reauthenticationCode) {
      setReauthCode(reauthenticationCode);
    }
  }, [openMonoConnect, reauthenticationCode]);

  const linkAccount = () => {
    monoConnect.open();
  };

  const canExport = hasPermission({
    permissions,
    scopes: ['statement-export', 'statement-*'],
  });

  const handleButtonToggle = (index) => {
    setIsButtonToggle(index);
  };

  const handleFundingToggle = () => {
    setIsFundingToggle(!isFundingToggle);
  };

  const goBackToAccounts = () => {
    history.goBack();
  };

  const openStatementModal = () => {
    openModal();
  };

  const handleSyncZoho = (account) => {
    dispatch(
      syncAccountingEntity({
        codes: [account.code],
        platform: 'zoho',
        element: 'balances',
      }),
    );
  };

  const isSchedule = () => {
    localStorage.setItem('schedule', 'on');
  };

  const handleUnlinking = (type, state) => {
    setUnlinking({ ...unlinking, [type]: state });
  };

  const handleUnlinkingActions = (type) => {
    dispatch(
      cancelMandate({
        code: account?.bankAccount,
        directDebit: type === 'directDebit',
        bankSync: type === 'bankSync',
        mandate: type === 'directDebit' ? account?.mandateCode : undefined,
      }),
    );
  };

  const actionHandler = (event, type) => {
    if (type === 'syncZoho') {
      return handleSyncZoho(account);
    }
  };

  useEffect(() => {
    if (success) {
      setUnlinking({ directDebit: false, linkedAccount: false });
      dispatch(getSingleBalance(account?.code));
      dispatch(getBalances());
    }
  }, [success]);

  const Actions = ({ type = 1 }) => {
    if (type !== 1)
      return (
        <div className="actions-dialog">
          <div
            className="actionLink"
            onClick={() => {
              dispatch(toggleAction({ selectedAccount: { ...account } }));
              handleButtonToggle(0);
            }}
          >
            <BankNoteO1Icon /> Single payment
          </div>

          <AllowedTo
            scopes={[
              'batch-transaction-*',
              'batch-transaction-view',
              'batch-transaction-create',
            ]}
          >
            <div
              className="actionLink"
              onClick={() => {
                history.push('/transactions/batch-payment');
              }}
            >
              <LayersTwoIcon /> Batch payments
            </div>
          </AllowedTo>

          <div
            className="actionLink"
            onClick={() => {
              dispatch(toggleAction({ selectedAccount: { ...account } }));
              handleButtonToggle(0);
              isSchedule();
            }}
          >
            <CalendarPlusIcon /> Schedule payment
          </div>
        </div>
      );

    return (
      <div className="actions-dialog">
        {!['linked', 'direct-debit'].includes(account?.accountType) && (
          <div className="actionLink" onClick={toggleNewAccountModal}>
            <span className="me-2 " style={{ fontSize: '0.875rem' }}>
              Create subaccount
            </span>
          </div>
        )}
        {canSync && account?.linkedOn && (
          <div className="actionLink" onClick={triggerDataSync}>
            <span className="me-2 " style={{ fontSize: '0.875rem' }}>
              Sync bank data
            </span>
          </div>
        )}

        <div
          className="actionLink mx-1"
          onClick={(event) => actionHandler(event, 'syncZoho')}
        >
          Sync to Zoho Books
        </div>
        {account?.mandateCode && (
          <div
            className="actionLink"
            onClick={() => handleUnlinking('directDebit', !unlinking.directDebit)}
          >
            <span className="me-2 " style={{ fontSize: '0.875rem' }}>
              Cancel direct debit
            </span>
          </div>
        )}

        {account?.isLinked && account?.linkedOn && (
          <div
            className="actionLink"
            onClick={() => handleUnlinking('linkedAccount', !unlinking.linkedAccount)}
          >
            <span className="me-2 text-danger" style={{ fontSize: '0.875rem' }}>
              Revoke access
            </span>
          </div>
        )}
      </div>
    );
  };
  const FundActions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={(event) => {
            addFunds(event);
            handleButtonToggle(0);
          }}
        >
          Add funds
        </div>

        <div
          className="actionLink"
          onClick={() => {
            handleButtonToggle(0);
            openModal('request');
          }}
        >
          Request funds
        </div>
      </div>
    );
  };

  return (
    <div className="w-100 pt-5">
      <div className="my-2 my-lg-0">
        <div
          className="back-click back-button gap-2 d-flex font-bold"
          onClick={goBackToAccounts}
          style={{ lineHeight: '20px', cursor: 'pointer', color: '#D28B28' }}
        >
          <ArrowLeftOutlined style={{ fontSize: 16, margin: 'auto 0px' }} />
          <span className="text-sm">Back</span>
        </div>

        <TopBar
          colClass="p-0"
          isRightBar
          breadcrumbs={[
            { title: 'Accounts', action: () => history.push('/accounts') },
            {
              title: account?.name,
              action: () => {
                null;
              },
            },
          ]}
        />

        <div
          style={{ paddingTop: '10px' }}
          className="d-md-flex align-items-start w-100 justify-content-between"
        >
          <div>
            <p
              style={{ marginBottom: 0, color: '#79716B', fontWeight: 500 }}
              className="mb-2"
            >
              {title || 'Cash Account'}
            </p>
            <div className="d-flex align-items-center mb-md-0 mb-3">
              <div className="d-flex me-2 gap-2 align-items-center">
                {ICONS[account?.currency]}
                <FormattedCurrency
                  value={account?.balance}
                  prefix={getCurrency(account?.currency)}
                  style={{
                    fontSize: 25,
                    color: '#1C1917',
                    fontWeight: 600,
                    fontStyle: 'normal',
                  }}
                />
              </div>
              <BadgeType
                value={{
                  // value: '6824238836',
                  color: '#44403C',
                  custom: (
                    <span
                      onClick={openDetailsModal}
                      className="d-flex align-items-center cursor"
                    >
                      <BankIcon className="me-1" width="16" height="16" />
                      {account?.number}
                      <ChevronRight className="ms-2" width="16" height="16" />
                    </span>
                  ),
                }}
              />
            </div>
          </div>
          {/* <h1 className="page-title">{title || 'Cash Account'}</h1> */}
          <div className="overview-action gap-3 p-0">
            {/* {canExport && (
              <div
                className={classNames('add-custom align-items-center cursor')}
                onClick={openStatementModal}
              >
                <span className="me-2 ">Generate statement</span>
              </div>
            )} */}

            {isAdmin && (
              <CustomPopover
                zIndex="1"
                content={<FundActions />}
                showPopover={isFundingToggle}
                clickOutside={handleFundingToggle}
                position="right"
                placement={isTabletOrMobile ? 'bottom' : 'bottom-end'}
                id={isTabletOrMobile ? 'invoice-schedule' : ''}
              >
                <ActiveButton
                  text="Add funds"
                  variant="light"
                  position="left"
                  className={classNames(
                    'add-custom add-button align-items-center d-flex gap-2 cursor px-3',
                    {
                      ['disabled']: disableActions,
                    },
                  )}
                  onClick={handleFundingToggle}
                  icon={<PlusDropDownIcon stroke="#242628" />}
                  customClass="add-custom d-flex align-items-center cursor"
                />
              </CustomPopover>
            )}

            {!isAdmin && (
              <div
                className={classNames('add-custom align-items-center cursor', {
                  ['disabled']: disableActions,
                })}
                onClick={addFunds}
              >
                <span className="me-2 ">Request funds</span>
              </div>
            )}

            <AllowedTo scopes={['transaction-*', 'transaction-create']}>
              <CustomPopover
                zIndex="1"
                content={<Actions type={2} />}
                showPopover={isButtonToggle === 1}
                clickOutside={() => handleButtonToggle(0)}
              >
                <SendFundsButton
                  disabled={disableActions}
                  onClick={() => handleButtonToggle(1)}
                />
              </CustomPopover>
            </AllowedTo>

            <CustomPopover
              zIndex="1"
              content={<Actions />}
              showPopover={isButtonToggle === 2}
              clickOutside={() => handleButtonToggle(0)}
            >
              <div
                onClick={() => handleButtonToggle(2)}
                className="add-custom border-0 cursor"
              >
                <MoreOutlined style={{ fontSize: '130%' }} />
              </div>
            </CustomPopover>
          </div>
        </div>
      </div>

      <Modal show={openMonoConnectAlert} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title={`Reconnect your account`}
          subTitle={`You need to reconnect your account in order to sync your bank data`}
          onConfirm={() => {
            setOpenMonoConnectAlert(false);
            linkAccount();
          }}
          onCancel={() => {
            dispatch(openMonoConnectAction());
            setOpenMonoConnectAlert(false);
          }}
          isDeleteDialog={false}
          dialogIcon={<RetryIcon />}
          actionBtnText="Connect"
        />
      </Modal>

      <Modal
        show={unlinking.directDebit || unlinking.linkedAccount}
        centered
        dialogClassName="custom-dialog"
      >
        {unlinking.directDebit ? (
          <DeleteDialog
            title="Cancel direct debit"
            subTitle="Are you sure you want to cancel your direct debit mandate? Canceling will prevent transactions from this account until a new mandate is created."
            deleteText="Cancel direct debit"
            onCancel={() => handleUnlinking('directDebit', !unlinking.directDebit)}
            cancelText="Keep"
            onDelete={() => handleUnlinkingActions('directDebit')}
            isLoading={loading}
            icon={<Caution />}
          />
        ) : (
          <DeleteDialog
            title="Revoke account access"
            subTitle="Are you sure you want to revoke access? This action will disconnect the account from your Bujeti dashboard. You can reconnect it anytime if you change your mind."
            deleteText="Revoke access"
            onCancel={() => handleUnlinking('linkedAccount', !unlinking.linkedAccount)}
            cancelText="Keep account"
            onDelete={() => handleUnlinkingActions('bankSync')}
            isLoading={loading}
            icon={<Caution />}
          />
        )}
      </Modal>
    </div>
  );
};

export default SubHeader;
