import { HintAlertIcon } from 'assets/icons';
import emptyProfileImg from 'assets/images/empty-profile.jpg';
import GroupedInput from 'components/UI/CustomInput/GroupedInput';
import CustomSelect from 'components/UI/CustomSelect';
import CustomTextarea from 'components/UI/CustomTextarea';
import FileUpload from 'components/UI/FileUpload';
import Loading from 'components/UI/Loading';
import { toastError } from 'components/UI/toast';
import { useEffect, useRef, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getCompany, updateCompany } from 'redux/actions/CompaniesAction';
import { businessTypes, companySize, getCompanySize, industries } from 'utils/helper';
import { isURL } from 'validator';
import '../../styles.scss';

const AdditionalDetails = () => {
  const [additionalInfo, setAdditionalInfo] = useState({});
  const [isUpload, setIsUpload] = useState(false);

  const inputRef = useRef(null);

  const dispatch = useDispatch();

  const {
    getCompany: { loading: loadingCompany, data: companyData },
    updateCompany: { loading, success },
  } = useSelector(({ companies }) => companies);

  useEffect(() => {
    if (companyData?.code)
      setAdditionalInfo({
        logo: '',
        companyName: companyData?.name || '',
        industry: companyData?.industry
          ? { value: companyData?.industry?.code, label: companyData?.industry?.name }
          : '',
        companyDescription: companyData?.description || '',
        employeeSize: getCompanySize(companyData?.size) || '',
        website: companyData?.website?.startsWith('https://')
          ? companyData?.website.replace('https://', '')
          : companyData?.website || '',
      });
  }, [companyData?.code]);

  useEffect(() => {
    if (additionalInfo?.logo) {
      setIsUpload(true);
      const payload = {
        toastMessage: 'Company logo updated',
        code: companyData?.code,
        logo: additionalInfo?.logo,
      };
      dispatch(updateCompany(payload));
    }
  }, [additionalInfo?.logo]);

  useEffect(() => {
    if (success) {
      dispatch(
        getCompany({
          code: companyData?.code,
          includeDocuments: true,
          includeSteps: true,
        }),
      );
      if (isUpload) {
        setAdditionalInfo({ ...AdditionalDetails, logo: '' });
        setIsUpload(false);
      }
    }
  }, [success]);

  const handleInputChange = ({ target: { name, value } }) => {
    setAdditionalInfo({ ...additionalInfo, [name]: value });
  };

  const onButtonClick = () => {
    if (inputRef.current) inputRef?.current?.click();
  };

  const handleSubmit = () => {
    const splitUrl = additionalInfo?.website?.split('.')?.length;

    if (!additionalInfo?.industry) return toastError('Please select your industry');
    if (!additionalInfo?.employeeSize)
      return toastError('Please select your employee size');

    if (!additionalInfo?.website) return toastError('Please enter your website');
    if (!isURL(additionalInfo?.website, { protocols: ['http', 'https'] })) {
      return toastError('Please enter a valid website');
    }
    if (additionalInfo?.website?.includes('www.') && splitUrl < 3)
      return toastError('Please enter a valid website (e.g www.example.com', {
        type: 'error',
      });

    if (!additionalInfo?.companyDescription)
      return toastError("Please enter your company's description");
    if (additionalInfo?.companyDescription?.length < 15)
      return toastError("Your company's description must be more than 15 characters", {
        type: 'error',
      });

    const payload = {
      toastMessage: 'Company details updated',
      code: companyData?.code,
      industry: additionalInfo?.industry?.value,
      size: additionalInfo.employeeSize.value,
      website: `https://${additionalInfo?.website}`,
      description: additionalInfo?.companyDescription,
    };
    dispatch(updateCompany(payload));
  };

  return (
    <div className="additional-details-container">
      <div className="title">
        <h3>Additional business details</h3>
        <p>
          Tell us a bit more about your business so we can tailor the product to your
          needs.
        </p>
      </div>

      <div className="additional-detail-fields">
        <div className="px-4">
          <div className="profile-image border-bottom bg-transparent py-4">
            <img
              src={companyData?.logoUrl ?? emptyProfileImg}
              alt="img"
              className="bg-black"
            />
            <div className="upload-container">
              <h6>Upload Logo</h6>
              <p>Images should be at least 400x400px, PNG or JPEG</p>
              <button
                onClick={onButtonClick}
                className="btn border xxs w-fit position-relative"
                type="button"
                disabled={loading}
              >
                {loading && isUpload ? <Loading color="#D28B28" size={18} /> : 'Upload'}
              </button>

              <div className="hidden">
                <FileUpload
                  name="logo"
                  ref={inputRef}
                  acceptedFile={{
                    'image/jpeg': ['.jpeg', '.jpg', '.png'],
                  }}
                  onChange={(value) =>
                    setAdditionalInfo({ ...additionalInfo, logo: value?.assetCode })
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p-3">
          <Row className="mb-2">
            <CustomSelect
              label="Industry"
              name="industry"
              placeholder="Select your industry"
              onChange={(value) => {
                setAdditionalInfo({ ...additionalInfo, industry: value });
              }}
              value={additionalInfo.industry}
              options={industries}
            />
          </Row>
          <Row className="mb-2">
            <CustomSelect
              label="Company size"
              name="employeeSize"
              placeholder="Select company size"
              onChange={(value) => {
                setAdditionalInfo({ ...additionalInfo, employeeSize: value });
              }}
              value={additionalInfo.employeeSize}
              options={companySize}
            />
          </Row>
          <Row className="mb-2">
            <GroupedInput
              label="Company website URL"
              name="website"
              placeholder="www.bujeti.com"
              onChange={handleInputChange}
              value={additionalInfo.website}
              options={businessTypes}
            />
            <Form.Text className="text-muted gap-1 d-flex align-items-center">
              <HintAlertIcon />
              <span className="text-xs">
                Your home page, blog, social media or company site.
              </span>
            </Form.Text>
          </Row>

          <Row className="mb-2">
            <CustomTextarea
              label="Company description"
              name="companyDescription"
              placeholder="Describe your company..."
              rowSize={3}
              onChange={handleInputChange}
              value={additionalInfo.companyDescription}
            />
            <Form.Text className="text-muted gap-1 d-flex align-items-center">
              <HintAlertIcon />
              <span className="text-xs">You can describe your company briefly.</span>
            </Form.Text>
          </Row>
        </div>

        <div className="border-top button-container w-100 px-3 py-3 me-md-auto d-md-flex align-items-center gap-3">
          <button
            disabled={loading}
            onClick={handleSubmit}
            className="btn black sm ms-md-auto"
          >
            {loading && !isUpload ? (
              <Loading color="#D28B28" size={18} />
            ) : (
              'Save and submit'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdditionalDetails;
