import {
  ArrowRight,
  BankNoteO1Icon,
  CalendarPlusIcon,
  CoinSwap,
  CoinsHand,
  CreditCard02,
  FileCheck,
  LayersTwoIcon,
  Move,
  PieChart02,
  PlusDropDownIcon,
  User03,
} from 'assets/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomPopover from 'components/UI/Popover';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toggleAction } from 'redux/actions/ToggleAction';
import AllowedTo from 'utils/AllowedTo';
import ImgCard from 'components/UI/ImgCard';

const SubHeader = ({ openSwapModal, openModal, firstName = '' }) => {
  const history = useHistory();
  const [isPopoverOpen, setIsPopoverOpen] = useState();
  const dispatch = useDispatch();

  const openStatementModal = () => {
    setIsPopoverOpen(false);
    openModal();
  };
  const Actions = () => {
    const navigateHandler = (screenType) => () => {
      history.push({
        pathname: screenType,
        state: { isModal: true },
      });
    };
    return (
      <div className="actions-dialog">
        <AllowedTo scopes={['budget-*', 'budget-create']}>
          <div onClick={navigateHandler('/expenses/budgets')} className="actionLink">
            <PieChart02 width={16} height={16} className="ms-1  me-2" /> Create a budget
          </div>
        </AllowedTo>
        <AllowedTo scopes={['employee-create', 'employee-*']}>
          <div onClick={navigateHandler('/people')} className="actionLink">
            <User03 width={16} height={16} className="ms-1  me-2" /> Add a member
          </div>
        </AllowedTo>
        <AllowedTo scopes={['card-*', 'card-create']}>
          <div onClick={navigateHandler('/cards')} className="actionLink">
            <CreditCard02 width={16} height={16} className="ms-1  me-2" /> Create a card
          </div>
        </AllowedTo>
        <AllowedTo scopes={['statement-view', 'statement-*', 'statement-export']}>
          <div onClick={openStatementModal} className="actionLink">
            <FileCheck width={16} height={16} stroke="#79716B" className="ms-1 me-2" />{' '}
            Generate statement
          </div>
        </AllowedTo>
        <AllowedTo scopes={['reimbursement-*', 'reimbursement-create']}>
          <div
            onClick={navigateHandler('/requests/reimbursements')}
            className="actionLink"
          >
            <CoinSwap width={16} height={16} className="ms-1  me-2" />
            Claim a reimbursement
          </div>
        </AllowedTo>
        <AllowedTo scopes={['reimbursement-*', 'reimbursement-create']}>
          <div onClick={navigateHandler('/requests/funds')} className="actionLink">
            <CoinsHand stroke="#79716B" width={16} height={16} className="ms-1 me-2" />{' '}
            Request funds
          </div>
        </AllowedTo>
      </div>
    );
  };

  const [isButtonToggle, setIsButtonToggle] = useState(false);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const isSchedule = () => {
    localStorage.setItem('schedule', 'on');
  };

  const PaymentActions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
          }}
        >
          <BankNoteO1Icon /> Single payment
        </div>
        <AllowedTo
          scopes={[
            'batch-transaction-*',
            'batch-transaction-view',
            'batch-transaction-create',
          ]}
        >
          <div
            className="actionLink"
            onClick={() => {
              history.push('/transactions/batch-payment');
            }}
          >
            <LayersTwoIcon /> Batch payments
          </div>
        </AllowedTo>
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
            isSchedule();
          }}
        >
          <CalendarPlusIcon /> Schedule payment
        </div>
      </div>
    );
  };

  const handleTogglePopover = () => setIsPopoverOpen(!isPopoverOpen);

  return (
    <div className="d-flex flex-md-row flex-column-reverse w-100 justify-content-between pt-4 mb-3 home-actions">
      <div className="overview-top">
        <h1 className="page-title mb-1">
          Welcome back,{' '}
          <span className="name-span" style={{ position: 'relative' }}>
            {firstName}
          </span>
        </h1>
        <p className="sub-text">Your Bujeti account overview and activity summary.</p>
      </div>

      <div className="ms-lg-auto mb-md-0 mb-2">
        <ImgCard
          size="large"
          customGap="c-gap-10"
          initials={<>{firstName && firstName.charAt(0)}</>}
        />
      </div>
    </div>
  );
};

export default SubHeader;
