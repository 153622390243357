import { DeleteIcon, PlusIcon } from 'assets/icons';
import { useMemo } from 'react';

import CustomInput from 'components/UI/CustomInput';
import CustomPercentageInput from 'components/UI/CustomPercentageInput';
import CurrencyFormat from 'react-currency-format';

import { Segmented, Skeleton } from 'antd';
import { convertNaNToZero, getCurrency } from 'utils/helper';

const Loader = () => {
  return (
    <div className="mt-4 w-75">
      <Skeleton.Input active size={20} style={{ borderRadius: '4px' }} />
      <div className=" details-holder">
        {Array.from({ length: 3 }).map((_, i) => (
          <div className="grid-col-3 pb-4" key={i}>
            {Array.from({ length: 3 }).map((_, index) => (
              <div className="d-flex flex-column mb-1" key={`${index}-input`}>
                {i == 0 && (
                  <Skeleton.Input active size={12} style={{ borderRadius: '4px' }} />
                )}
                <Skeleton.Input
                  active
                  size={40}
                  style={{ borderRadius: '8px', width: '100%' }}
                />
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="d-flex justify-content-end">
        <div className="details-holder sub-total">
          <div className="d-flex border-bottom">
            <Skeleton.Input active size={12} style={{ borderRadius: '4px' }} />
            <div className="ms-auto inner-text">
              <Skeleton.Input
                active
                size={12}
                style={{ borderRadius: '4px', width: 10 }}
              />
            </div>
          </div>

          <div className="d-flex align-items-center gap-2 mt-4">
            <Skeleton.Input
              className="pt-2"
              active
              size={12}
              style={{ borderRadius: '4px' }}
            />
            <Skeleton.Input active size={40} style={{ borderRadius: '4px' }} />
          </div>

          <div className="d-flex align-items-center gap-2 mt-2 pb-3">
            <Skeleton.Input
              active
              className="pt-2"
              size={12}
              style={{ borderRadius: '4px' }}
            />
            <Skeleton.Input active size={40} style={{ borderRadius: '4px' }} />
          </div>

          <div className="border-top">
            <div className="d-flex pt-4">
              <Skeleton.Input active size={12} style={{ borderRadius: '4px' }} />

              <Skeleton.Input
                active
                className="ms-auto"
                size={12}
                style={{ borderRadius: '4px', height: 18 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductsRow = ({
  values,
  onRemoveRow,
  getProductValue,
  onHandleAddnew,
  isLoading,
  discountType,
  handleDiscountType,
  getFooterValue,
  onPercentageChange,
  subTotal,
  totalAmount,
  vat,
}) => {
  const isPercentage = useMemo(() => discountType === 'percentage', [discountType]);
  const discount = useMemo(() => {
    if (values?.discount_value) {
      let value = values?.discount_value || 0;
      if (isPercentage) value = ((values?.discount_value / 100) * subTotal).toFixed(2);
      return convertNaNToZero(value);
    }
  }, [values?.discount_value, subTotal]);

  return (
    <>
      {isLoading ? (
        <div className="border-top mt-4">
          <Loader />
        </div>
      ) : (
        <>
          <section className="border-top mt-4 pb-2">
            <div>
              <h2 className="region-header mt-4 pt-2">Products and services</h2>
              <div className="details-holder details-holder-width">
                {values?.products?.map((product, index) => {
                  return (
                    <div className="row mb-3 fade-in" key={index}>
                      <div className="col-md-4 form-group">
                        {index === 0 && <label className="form-label">Items</label>}
                        <CustomInput
                          type="text"
                          name="name"
                          value={product.name}
                          onChange={(event) => getProductValue(index, event)}
                          placeholder="Enter item name"
                        />
                      </div>

                      <div className="col-md-2 form-group">
                        {index === 0 && <label className="form-label">Qty</label>}
                        <CustomInput
                          name="quantity"
                          type="number"
                          value={product.quantity}
                          onWheel={(event) => {
                            event.target.blur();
                            event.stopPropagation();
                          }}
                          maxLength="4"
                          placeholder="0"
                          onChange={(event) => getProductValue(index, event)}
                        />
                      </div>

                      <div className="col-md-3 form-group">
                        {index === 0 && <label className="form-label">Unit price</label>}
                        <CustomInput
                          name="unitPrice"
                          type="number"
                          placeholder="₦0.00"
                          isAmount
                          value={isNaN(product.unitPrice) ? undefined : product.unitPrice}
                          otherCurrency={false}
                          useCurrency={false}
                          onChange={(event) => getProductValue(index, event)}
                        />
                      </div>

                      <div className="col-md-2 d-flex justify-content-between">
                        <div className="form-group w-100">
                          {index === 0 && <p className="form-label">Amount excl. Tax</p>}

                          <div className="amount-holder">
                            <CurrencyFormat
                              style={{
                                color: '#57534E',
                                fontSize: '0.875rem',
                                overflow: 'hidden',
                              }}
                              prefix={getCurrency(values?.currency?.value)}
                              value={convertNaNToZero(
                                product.unitPrice * product.quantity,
                              )}
                              displayType="text"
                              thousandSeparator={true}
                              isNumericString
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className={`col-md-1 d-flex align-items-center ${
                          index == 0 ? 'mt-4' : ''
                        }`}
                      >
                        {values?.products?.length > 1 && (
                          <div
                            className="add-button add-action none"
                            onClick={() => onRemoveRow(index)}
                          >
                            <DeleteIcon stroke="#79716B" height="16" width="16" />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}

                <button
                  className="add-item"
                  style={{ width: 'fit-content' }}
                  onClick={onHandleAddnew}
                >
                  <PlusIcon stroke="#D28B28" height="14" width="14" /> Add additional item
                </button>
              </div>

              {/* Extras Here */}
              <div className="d-flex justify-content-end details-holder-width">
                <div className="details-holder sub-total">
                  {/* Subtotal Here  */}
                  <div className="d-flex border-bottom">
                    <p className="inner-text">Subtotal excl. Tax</p>
                    <p className="ms-auto inner-text">
                      <CurrencyFormat
                        prefix={getCurrency(values?.currency?.value)}
                        value={convertNaNToZero(subTotal)}
                        displayType="text"
                        thousandSeparator={true}
                        isNumericString
                      />
                    </p>
                  </div>

                  <div className="py-3">
                    {/* Discount Here */}

                    <div className="calc-region mb-3">
                      <div className="inner-holder">
                        <span className="text">Discount</span>
                        <div className="d-flex align-items-center gap-2">
                          <Segmented
                            value={discountType}
                            options={[
                              { value: 'percentage', label: '%' },
                              { value: 'amount', label: '₦' },
                            ]}
                            onChange={(value) => {
                              handleDiscountType(value);
                            }}
                          />

                          <div className="form-group">
                            {isPercentage ? (
                              <CustomPercentageInput
                                placeholder="0%"
                                name="discount_value"
                                value={values?.discount_value}
                                onChange={(value) =>
                                  onPercentageChange({ name: 'discount_value', value })
                                }
                              />
                            ) : (
                              <CustomInput
                                name="discount_value"
                                onChange={getFooterValue}
                                type="discount_value"
                                otherCurrency={false}
                                useCurrency={false}
                                isAmount
                                placeholder="₦"
                                value={values?.discount_value}
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <span className="value">
                        {values?.discount_value && (
                          <CurrencyFormat
                            prefix={`- ${getCurrency(values?.currency?.value)}`}
                            value={convertNaNToZero(discount)}
                            displayType="text"
                            thousandSeparator={true}
                            isNumericString
                          />
                        )}
                      </span>
                    </div>

                    {/* VAT Here */}
                    <div className="calc-region">
                      <div className="inner-holder">
                        <span className="text">VAT %</span>

                        <div className="form-group w-100">
                          <CustomPercentageInput
                            placeholder="0%"
                            name="vat"
                            value={values?.vat}
                            onChange={(value) =>
                              onPercentageChange({ name: 'vat', value })
                            }
                          />
                        </div>
                      </div>

                      <span className="value">
                        {values?.vat && (
                          <CurrencyFormat
                            prefix={getCurrency(values?.currency?.value)}
                            value={convertNaNToZero(vat)}
                            displayType="text"
                            thousandSeparator={true}
                            isNumericString
                          />
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="border-top pt-4 d-flex align-items-center">
                    <p className="inner-text">TOTAL incl. Tax </p>
                    <p className="ms-auto inner-text">
                      <CurrencyFormat
                        style={{ fontWeight: 600, fontSize: '20px' }}
                        prefix={getCurrency(values?.currency?.value)}
                        value={convertNaNToZero(totalAmount)}
                        displayType="text"
                        thousandSeparator={true}
                        isNumericString
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default ProductsRow;
