import { TransactionLogo } from 'assets/images';
import NoData from 'components/NoData';
import TopBar from 'components/TopBar';
import CustomButton from 'components/UI/CustomButton';
import Loading from 'components/UI/Loading';
import CustomPopover from 'components/UI/Popover';
import { jsPDF } from 'components/UIHooks/JsPDF';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { exportCategories, fetchCategories } from 'redux/actions/CategoryAction';
import { buildExportCategoriesData } from 'utils/helper';
import { categoryColumns, exportCategoriesColumns } from 'utils/mockData';
import { getFromAndTo } from 'utils/utility';
import { ReactComponent as DownArrow } from 'assets/icons/down-arrow.svg';
import CategoryEmptyState from './CategoryEmptyState';
import SearchLoader from './SearchLoader';

const CategoryHeader = ({
  handleSelect = () => null,
  filteredQuery,
  setFilteredQuery,
  children,
  addExport = true,
  hasEditButton = true,
  hasSaveChangesButton = false,
  handleEdit,
  handleSaveChanges,
  page = 'categories',
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    fetchCategories: { data: { categories = [], meta = {} } = {}, loading },
    exportCategories: {
      data: { categories: exportData = [] } = {},
      loading: exportLoading,
    },
  } = useSelector(({ categories }) => categories);
  const { selectedDateRangeTopbar } = useSelector(({ overview }) => overview);

  const [isButtonToggle, setIsButtonToggle] = useState(false);
  const [search, setSearch] = useState('');
  const [filterData, setFilterData] = useState([]);
  const debouncedValue = useDebounce(search, 600);
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const { from, to } = getFromAndTo(selectedDateRangeTopbar?.range);
  const queryParams = { from, to };

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const columns = useMemo(() => categoryColumns, [categories]);

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => history.push('/compliances/categories/create')}
        >
          Create a category
        </div>
        <div
          onClick={() => history.push('/compliances/categories/multiple/create')}
          className="actionLink"
        >
          Create multiple
        </div>
        <div
          onClick={() => history.push('/compliances/categories/multiple/upload')}
          className="actionLink"
        >
          Upload
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (debouncedValue) {
      filteredQuery.search = debouncedValue;
      dispatch(fetchCategories({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered) {
      delete filteredQuery.search;
      dispatch(fetchCategories({ ...filteredQuery }));
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const handleFilter = (query) => {
    const queryPayload = {
      ...filteredQuery,
      ...query,
    };

    setFilteredQuery(queryPayload);

    dispatch(fetchCategories(queryPayload));
    isFiltered.current = !!Object.keys(query).length;
  };

  const clearFilters = () => {
    setFilteredQuery({ ...queryParams });
    setFilterData([]);
  };

  const handleExport = () => {
    dispatch(exportCategories({ ...filteredQuery, search: debouncedValue ?? undefined }));
  };

  const handleExportPlatform = (platform) => {
    dispatch(exportCategories({ ...filteredQuery, search: debouncedValue ?? undefined }));
  };

  const exportPDF = () => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const columnStyles = {};
    const title = 'Categories Report';
    const headers = [
      exportCategoriesColumns
        .filter((item) => item.Header !== 'Files')
        .map((item) => item.Header),
    ];

    columns.forEach((column, index) => {
      columnStyles[index] = { cellWidth: 60 }; // Adjust the width as needed
    });

    const body = buildExportCategoriesData(exportData);

    let content = {
      startY: 80,
      head: headers,
      body,
      columnStyles,
    };

    doc.addImage(TransactionLogo, 'JPEG', marginLeft, 25, 90, 50.5);
    // doc.text(title, 80, 40);
    doc.autoTable(content);
    doc.save('Categories.pdf');
  };
  const csvData = [
    exportCategoriesColumns
      .filter((item) => item.Header !== 'Files')
      .map((item) => item.Header),
    ...buildExportCategoriesData(exportData),
  ];

  const show = !!categories?.length || (filtered && !categories?.length);

  return (
    <>
      <TopBar
        headerText="Categories"
        subText="Organize and access your categories"
        addButton
        breadcrumbs={
          page !== 'categories' &&
          [
            {
              title: 'Categories',
              action: () => history.push('/compliances/categories'),
            },
            {
              title: page,
            },
          ].filter((item) => item.title)
        }
        dropDownBtn={
          <CustomPopover
            zIndex="1"
            showPopover={isButtonToggle}
            clickOutside={handleButtonToggle}
            content={<Actions />}
          >
            <CustomButton className="add-button" onClick={handleButtonToggle}>
              Create a category
              <DownArrow />
            </CustomButton>
          </CustomPopover>
        }
        withDate
        addActionButton={'Create a subcategory'}
        toggleActionButton={() =>
          history.push('/compliances/categories/subcategories/create')
        }
      />

      <section>
        {loading && !filtered ? (
          <SearchLoader hasExport={true} hasAddbtn={true} />
        ) : (
          <TopBar
            inputPlaceholder="Search"
            showBarSearch={show}
            searchVal={search}
            setSearchVal={setSearch}
            handleFilterApply={handleFilter}
            showFilter={show}
            addExport={show && addExport}
            withDate
            withOutSearch={true}
            hasEditButton={show && hasEditButton}
            hasSaveChangesButton={show && hasSaveChangesButton}
            clearFilters={clearFilters}
            handleExport={handleExport}
            handleEdit={handleEdit}
            handleSaveChanges={handleSaveChanges}
            handleExportPlatform={handleExportPlatform}
            csvData={csvData}
            exportPDF={exportPDF}
            csvFileName="Categories Report.csv"
          />
        )}

        <>
          {!categories.length && !loading ? (
            <div>
              {isFiltered.current ? (
                <div className="tabinnerWrapper">
                  <NoData
                    headerText={`You have no category for this filter`}
                    bodyText="Alter filter to see category"
                    withButton={true}
                    buttonLabel="View all categories"
                    onClickHandler={() => {
                      clearFilters();
                      dispatch(fetchCategories());
                    }}
                  />
                </div>
              ) : (
                <CategoryEmptyState handleSelect={handleSelect} />
              )}
            </div>
          ) : (
            children
          )}
        </>
      </section>
    </>
  );
};

export default CategoryHeader;
